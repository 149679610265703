import { reportError } from '@perpay-web/utils/errorHandlers';
import { useMountAndUnmount } from '@perpay-web/hooks/useMountAndUnmount';
import { useTimedEvent } from '@perpay-web/hooks/useTimedEvent';
import LoaderCircle from '@perpay-web/components/base/LoaderCircle/LoaderCircle';

const WARNING_TIMEOUT_MS = 15000; // 15 seconds

const WarningLoaderCircle = (props) => {
    const { startTimer, clearTimer } = useTimedEvent(() => {
        reportError('Infinite loader');
    }, WARNING_TIMEOUT_MS);

    useMountAndUnmount(() => {
        startTimer();
        return () => clearTimer();
    });

    return <LoaderCircle {...props} />;
};

export default WarningLoaderCircle;
