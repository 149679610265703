import { createDataModuleActions } from '@perpay-web/data-module/createDataModuleActions';
import { createDataModuleReducer } from '@perpay-web/data-module/createDataModuleReducer';
import { merge, NEVER } from 'rxjs';
import { ofType } from '@perpay-web/observable/operators/ofType';
import { normalize, schema } from 'normalizr';
import { mergeMap, withLatestFrom } from 'rxjs/operators';
import {
    LOADING_STATE,
    UNREQUESTED_STATE,
} from '@perpay-web/data-module/constants';

const getSingleArrayOfObjectsKey = (result) => {
    const keys = Object.keys(result);
    if (keys.includes('uuid')) {
        return '';
    }

    const arrayOfObjectsKeys = keys.filter(
        (key) => Array.isArray(result[key]) && typeof result[key] === 'object',
    );
    if (arrayOfObjectsKeys.length !== 1) {
        throw new Error('multiple arrays found for crud data module');
    }

    return arrayOfObjectsKeys[0];
};

// TABLE and entitySchema are passed to normalizr to structure the record data in a
// uuid-based key-value mapping
const TABLE = 'data';
const entitySchema = new schema.Entity(TABLE, {}, { idAttribute: 'uuid' });
const allWrapperSchema = { all: [entitySchema] };
const getUnionSchemaKey = (value) => {
    const key = getSingleArrayOfObjectsKey(value);
    return key && value[key] ? 'isAllWrapper' : 'isEntity';
};
const crudSchema = new schema.Union(
    {
        isEntity: entitySchema,
        isAllWrapper: allWrapperSchema,
    },
    getUnionSchemaKey,
);

const getNormalizedData = (payload) => {
    const payloadHasContent = payload && Object.keys(payload).length > 0;
    if (!payloadHasContent) {
        return {};
    }

    const normalizedWrapper = normalize(payload, crudSchema);
    return normalizedWrapper.entities.data;
};

const toArray = (maybeDataModule) => {
    if (!maybeDataModule) {
        return [];
    }

    return Array.isArray(maybeDataModule) ? maybeDataModule : [maybeDataModule];
};

/**
 * Behaviors:
 * 1. Create, read, updates and deletes modify the same locally cached data
 * 2. Individual data modules still manage the request lifecycle for the individual operations,
 *  e.g. errors in fetch are not cleared or set by errors in update, but the most recent
 *  error is stored in the CRUD Data Module.
 * 3. The stored data is normalized for convenient access to invidiual records
 */
export const createCrudDataModule =
    ({ create, read, update, delete: del }) =>
    ({ getRoot }) => {
        const createDataModules = toArray(create);
        const readDataModules = toArray(read);
        const updateDataModules = toArray(update);
        const deleteDataModules = toArray(del);

        const requestActions = [
            ...createDataModules,
            ...readDataModules,
            ...updateDataModules,
            ...deleteDataModules,
        ].map((m) => m.dataRequest().type);
        const errorActions = [
            ...createDataModules,
            ...readDataModules,
            ...updateDataModules,
            ...deleteDataModules,
        ].map((m) => m.dataError().type);
        const nonDeleteSuccessActions = [
            ...createDataModules,
            ...readDataModules,
            ...updateDataModules,
        ].map((m) => m.dataSuccess().type);
        const deleteSuccessActions = deleteDataModules.map(
            (m) => m.dataSuccess().type,
        );

        const key = `CRUD::${requestActions[0]}`;
        // Create a reducer to store this data and normalize on uuid
        // Create an epic similar to composeDataModules
        const { dataRequest, dataError, dataReset, dataSuccess } =
            createDataModuleActions(key);
        const DATA_REQUEST = dataRequest().type;
        const DATA_ERROR = dataError().type;
        const DATA_SUCCESS = dataSuccess().type;
        const DATA_RESET = dataReset().type;

        const reducer = createDataModuleReducer(
            DATA_REQUEST,
            DATA_ERROR,
            DATA_SUCCESS,
            DATA_RESET,
        );

        const composedEpic = (action$, state$) =>
            merge(
                // Requests
                action$.pipe(
                    ofType(...requestActions),
                    mergeMap(() => [dataRequest()]),
                ),
                // Errors
                action$.pipe(
                    ofType(...errorActions),
                    mergeMap((action) => [dataError(action.payload)]),
                ),
                // Successes
                action$.pipe(
                    ofType(...nonDeleteSuccessActions),
                    withLatestFrom(state$),
                    mergeMap(([action, state]) => {
                        const { value } = getRoot(state);
                        const normalizedData = getNormalizedData(
                            action.payload,
                        );
                        return [
                            dataSuccess({
                                ...value,
                                ...normalizedData,
                            }),
                        ];
                    }),
                ),
                deleteDataModules.length > 0
                    ? action$.pipe(
                          ofType(...deleteSuccessActions),
                          withLatestFrom(state$),
                          mergeMap(([action, state]) => {
                              const { value } = getRoot(state);
                              const {
                                  [action.payload.uuid]: deletedEntity,
                                  ...newValue
                              } = value;
                              return [dataSuccess({ ...newValue })];
                          }),
                      )
                    : NEVER,
                // Resets
                // We do not consume reset actions from the constituent data modules
            );

        return {
            composedEpic,
            reducer,
            dataRequest,
            dataError,
            dataReset,
            dataSuccess,
            getIsUnrequested: (state) =>
                getRoot(state).requestState === UNREQUESTED_STATE,
            getIsLoading: (state) =>
                getRoot(state).requestState === LOADING_STATE,
            getData: (state) => getRoot(state).value,
            getErrors: (state) => getRoot(state).errors,
        };
    };
