import { ofType } from 'redux-observable';
import { exhaustMap } from 'rxjs/operators';
import { dispatchObservable } from '@perpay-web/observable/dispatchObservable';
import { setCardStatementsError } from '@perpay-web/fintech/actions/entities/cardStatements';
import { fetchFeatures } from '@perpay-web/fintech/actions/entities/features';
import {
    gateCardAccount as gateCardAccountAction,
    gateCardAccountError,
    gateCardAccountSuccess,
} from '@perpay-web/fintech/actions/entities/gateCardAccount';
import { routeToLocation } from '@perpay-web/fintech/actions/router';
import {
    STORE_FETCH_FEATURES_ERROR,
    STORE_REPLACE_FEATURES,
} from '@perpay-web/fintech/constants/actionTypes';
import { paths } from '@perpay-web/fintech/props/appPaths';
import { getNotification as getCardStatus } from '@perpay-web/fintech/dataModules/fetchCardDashboardSummary';
import {
    NO_APPLICATION,
    NOT_ENABLED,
    APPLICATION_REJECTED,
    APPLICATION_ABANDONED,
} from '@perpay-web/fintech/utils/cardDashboardNotificationUtils';

export const gateCardAccount = (action$, state$) =>
    action$.pipe(
        ofType(gateCardAccountAction().type),
        exhaustMap(() =>
            dispatchObservable({
                action$,
                state$,
                initialDispatch: () => [fetchFeatures()],
                waitFor: [STORE_REPLACE_FEATURES],
                waitForDispatch: (state) => {
                    const maybeRedirectAction = [
                        NO_APPLICATION,
                        NOT_ENABLED,
                        APPLICATION_REJECTED,
                        APPLICATION_ABANDONED,
                    ].includes(getCardStatus(state))
                        ? [
                              routeToLocation({
                                  path: paths.cardLearn.path,
                                  replace: true,
                              }),
                          ]
                        : [];

                    return [...maybeRedirectAction, gateCardAccountSuccess()];
                },
                errors: [
                    STORE_FETCH_FEATURES_ERROR,
                    setCardStatementsError().type,
                ],
                errorDispatch: () => [
                    routeToLocation({
                        path: paths.dashboard.path,
                        replace: true,
                    }),
                    gateCardAccountError(),
                ],
            }),
        ),
    );
