import { useCallback, useMemo, useRef, useState } from 'react';

import { useMountAndUnmount } from '@perpay-web/hooks/useMountAndUnmount';
import { getClassName } from '@perpay-web/utils/domUtils';

import styles from './Modal.scss';

const MODAL_CLOSING_CLASSNAME = styles['modal--closing'];
const MODAL_OPENED_CLASSNAME = styles['modal--open'];

const uniqueClassNamePrefix = 'modal-unique-id';

const SAFETY_TRANSITION_MS = 350; // transition duration 300ms + 50ms

let uniqueClassNameId = 1;
const getNextUniqueClassNameId = () => {
    const id = uniqueClassNameId;
    uniqueClassNameId += 1;

    return id;
};

const hasParentWithClassName = (node, className) => {
    const parents = [...document.getElementsByClassName(className)];
    return parents.some((parent) => parent.contains(node));
};

const cancelAnimationFrame = (animationRequestId) => {
    if (animationRequestId) {
        window.cancelAnimationFrame(animationRequestId);
    }
};

/**
 * useAnimatedModal is intended as a wrapper around useModal to ensure that the modal
 * does not close before it's closing animation completes.
 *
 * See the Modal storybook stories for sample usage
 *
 * ```
 *
 * const AnimatedModal = () => {
 *     const { isModalOpen, setIsModalOpen } = useModal();
 *     const {
 *         isAnimatedModalOpen,
 *         setIsAnimatedModalOpen,
 *         animatedModalClassName,
 *     } = useAnimatedModal({ isModalOpen, setIsModalOpen });
 *     return (
 *          <div>
 *              My cool component
 *              {isAnimatedModalOpen ? (
 *                  <Modal
 *                      className={animatedModalClassName}
 *                      onClose={() => setIsAnimatedModalOpen(false)})}
 *                  >
 *                      <p>Hello animated modal world</p>
 *                  </Modal>
 *              ) : null}
 *              <button onClick={() => setIsAnimatedModalOpen(true)}
 *          </div>
 *      );
 * }
 * ```
 */
export const useAnimatedModal = ({ isModalOpen, setIsModalOpen }) => {
    const [isAnimatedModalOpen, setIsAnimatedModalOpenInternal] =
        useState(isModalOpen);
    const [openAnimationRequestId, setOpenAnimationRequestId] = useState();
    const [animatedModalClassName, setAnimatedModalClassName] = useState('');
    const safetyTimerIdRef = useRef(null);
    const uniqueClassName = useMemo(
        () => `${uniqueClassNamePrefix}${getNextUniqueClassNameId()}`,
        [],
    );

    const closeAnimatedModal = (element) => {
        const isThisModal = hasParentWithClassName(element, uniqueClassName);
        if (!isThisModal) {
            return;
        }

        const hasOpenClass = hasParentWithClassName(
            element,
            styles['modal--open'],
        );
        if (hasOpenClass) {
            return;
        }

        if (safetyTimerIdRef.current) {
            clearTimeout(safetyTimerIdRef.current);
        }

        window.requestAnimationFrame(() =>
            setAnimatedModalClassName(MODAL_CLOSING_CLASSNAME),
        );
        setIsAnimatedModalOpenInternal(false);
        setIsModalOpen(false);
    };

    useMountAndUnmount(() => {
        const onTransitionEnd = (e) => {
            closeAnimatedModal(e.target);
        };

        document.addEventListener('transitionend', onTransitionEnd);

        if (isAnimatedModalOpen) {
            const reqId = window.requestAnimationFrame(() =>
                setAnimatedModalClassName(MODAL_OPENED_CLASSNAME),
            );
            setOpenAnimationRequestId(reqId);
        }

        return () => {
            cancelAnimationFrame(onTransitionEnd);
            document.removeEventListener('transitionend', onTransitionEnd);
        };
    });

    const setIsAnimatedModalOpen = useCallback(
        (nextIsModalOpen) => {
            if (isAnimatedModalOpen === nextIsModalOpen) {
                return;
            }

            if (!nextIsModalOpen) {
                cancelAnimationFrame(openAnimationRequestId);

                // Set a backup mechanism for closing the modal if the transitionend
                // event fails to fire
                safetyTimerIdRef.current = setTimeout(() => {
                    const element =
                        document.getElementsByClassName(uniqueClassName)[0];
                    closeAnimatedModal(element);
                }, SAFETY_TRANSITION_MS); // transition length + 200

                window.requestAnimationFrame(
                    () => {
                        setAnimatedModalClassName(MODAL_CLOSING_CLASSNAME);
                    }, // transitionend will complete the close
                );
                return;
            }

            setIsAnimatedModalOpenInternal(nextIsModalOpen);
            setIsModalOpen(nextIsModalOpen);
            const reqId = window.requestAnimationFrame(() =>
                setAnimatedModalClassName(MODAL_OPENED_CLASSNAME),
            );
            setOpenAnimationRequestId(reqId);
        },
        [
            setIsModalOpen,
            isAnimatedModalOpen,
            setAnimatedModalClassName,
            setIsAnimatedModalOpenInternal,
            openAnimationRequestId,
        ],
    );

    return {
        isAnimatedModalOpen,
        setIsAnimatedModalOpen,
        animatedModalClassName: getClassName(
            animatedModalClassName,
            uniqueClassName,
            styles['modal--animate'],
        ),
    };
};
