import { getClassName } from '@perpay-web/utils/domUtils';
import { noop } from '@perpay-web/utils/noop';

import Clickable from '@perpay-web/components/base/Clickable/Clickable';

import styles from './Alert.scss';

export const AlertIcons = {
    EXCLAMATION: 'exclamation',
    PACKAGE: 'package',
    CREDIT_CARD_GREEN: 'credit-card-green',
    DONE_MARK: 'done-mark',
    ENVELOPE: 'envelope',
    PERSON_LIFTING_WEIGHTS: 'person-lifting-weights',
    CHART_INCREASING: 'chart-increasing',
    PARTY_FACE: 'party-face',
    SMILING_FACE_WITH_SUNGLASSES: 'smiling-face-with-sunglasses',
    POLICE_CAR_LIGHT: 'police-car-light',
    STOP_SIGN: 'stop-sign',
    MAGNIFYING_GLASS_TILTED_RIGHT: 'magnifying-glass-tilted-right',
    PAGE_FACING_UP: 'page-facing-up',
};

export const AlertSeverity = {
    INFO: 'info',
    SUCCESS: 'success',
    WARNING: 'warning',
    DANGER: 'danger',
    CLOSED: 'closed',
};

const Alert = ({
    icon,
    severity,
    content = null,
    callToActionRoute = '',
    className = '',
    onClick = noop,
}) => {
    if (!content) {
        return null;
    }

    const renderIcon = Object.values(AlertIcons).includes(icon) ? (
        <div className={styles[`icon__${icon}`]} />
    ) : null;
    const severityClass = severity.length
        ? styles[`alert--${severity}`]
        : styles[`alert--info`];
    const isClickable = callToActionRoute.length > 0;

    const body = (
        <div className={styles.alert__container}>
            <div className={styles.content}>
                <div className={styles.icon}>{renderIcon}</div>
                <div className={styles.content__message}>
                    <span className={styles.content__copy}>{content}</span>
                </div>
            </div>
        </div>
    );

    const containerClassName = getClassName(
        styles.alert,
        severityClass,
        className,
    );

    if (isClickable) {
        return (
            <Clickable className={containerClassName} onClick={onClick}>
                {body}
            </Clickable>
        );
    }

    return <div className={containerClassName}>{body}</div>;
};

export default Alert;
