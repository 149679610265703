import { forwardRef } from 'react';

import { getClassName } from '@perpay-web/utils/domUtils';

import styles from './CustomSharedLink.scss';

const CustomSharedLink = forwardRef(
    (
        {
            onClick,
            children = null,
            href,
            target = null,
            className = '',
            action = 'inline-link',
            size = '',
            loading,
            ...rest
        },
        ref,
    ) => (
        <a
            ref={ref}
            href={href}
            className={getClassName(
                styles.link,
                styles[`link--${action}`],
                size ? styles[`link--${size}`] : '',
                className,
            )}
            onKeyPress={onClick}
            onClick={onClick}
            target={target}
            {...rest}
        >
            {children}
        </a>
    ),
);

CustomSharedLink.displayName = 'CustomSharedLink';

export default CustomSharedLink;
