import { lazy } from 'react';
import { Route } from 'react-router-dom';

import { compose } from '@perpay-web/utils/functionalUtils';

import { withLoader } from '@perpay-web/components/higher-order/WithLoader/WithLoader';
import { withPerpayTitle } from '@perpay-web/components/higher-order/WithPerpayTitle/WithPerpayTitle';
import { paths } from '@perpay-web/fintech/props/appPaths';
import { importBorrowerSignup } from '@perpay-web/fintech/utils/imports/importBorrowerSignup';
import { importBorrowerLoginContainer } from '@perpay-web/fintech/utils/imports/importBorrowerLoginContainer';
import { importCardInvite } from '@perpay-web/fintech/utils/imports/importCardInvite';
import { withSignupRedirectCheck } from '@perpay-web/fintech/components/higher-order/WithSignupRedirectCheck/WithSignupRedirectCheck';
import { withLoginRedirectCheck } from '@perpay-web/fintech/components/higher-order/WithLoginRedirectCheck/WithLoginRedirectCheck';
import { withCardFirstSignupVerifyInviteCode } from '@perpay-web/fintech/components/higher-order/WithCardFirstSignupVerifyInviteCode/WithCardFirstSignupVerifyInviteCode';
import BorrowerLogout from '@perpay-web/fintech/components/containers/BorrowerLogoutContainer';
import { CARD_SIGNUP_MVP_IS_LAUNCHED } from '@perpay-web/fintech/constants/flags';
import { withBottomNav } from '@perpay-web/components/higher-order/WithBottomNav/WithBottomNav';
import { importPartnerOnboardingSignupContainer } from '@perpay-web/fintech/utils/imports/importPartnerOnboardingSignupContainer';

const EmptyBottomNav = () => null;
const BorrowerLoginRoute = compose(
    withPerpayTitle('Login'),
    withLoginRedirectCheck,
    withBottomNav(EmptyBottomNav),
)(withLoader(lazy(importBorrowerLoginContainer)));
const PartnerSignupRoute = compose(
    withPerpayTitle('Sign up'),
    withLoginRedirectCheck,
    withBottomNav(EmptyBottomNav),
)(withLoader(lazy(importPartnerOnboardingSignupContainer)));

const identity = (x) => x;

const BorrowerSignupRoute = compose(
    withPerpayTitle('Sign up'),
    withSignupRedirectCheck,
    withBottomNav(EmptyBottomNav),
    CARD_SIGNUP_MVP_IS_LAUNCHED
        ? withCardFirstSignupVerifyInviteCode
        : identity,
)(withLoader(lazy(importBorrowerSignup)));

const CardInviteRoute = compose(
    withPerpayTitle("You're invited!"),
    withBottomNav(EmptyBottomNav),
    withSignupRedirectCheck,
)(withLoader(lazy(importCardInvite)));

export const renderAuthenticatingRoutes = () => [
    <Route path={paths.signUp.path} Component={BorrowerSignupRoute} />,
    <Route path={paths.cardInvite.path} Component={CardInviteRoute} />,
    <Route path={`${paths.login.path}/*`} Component={BorrowerLoginRoute} />,
    <Route path={paths.logout.path} Component={BorrowerLogout} />,
    <Route
        path={paths.partnerOnboardingInvitation.path}
        Component={PartnerSignupRoute}
    />,
];
