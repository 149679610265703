/* eslint-disable max-len */

import { useState } from 'react';
import { useLocation } from 'react-router-dom';

import CustomButton from '@perpay-web/components/base/CustomButton/CustomButton';
import { isAppBrowserAgent } from '@perpay-web/utils/userAgentUtils';
import { getClassName } from '@perpay-web/utils/domUtils';
import HeaderDrawerMenu from '@perpay-web/fintech/components/base/HeaderDrawerMenu/HeaderDrawerMenu';
import CustomLink from '@perpay-web/fintech/components/base/CustomLink/CustomLink';
import { focusFlowPaths, paths } from '@perpay-web/fintech/props/appPaths';
import { HOME_PAGE_URL } from '@perpay-web/fintech/constants/urls';
import { useHeaderRightNavRender } from '@perpay-web/fintech/hooks/useHeaderRightNavContext';
import { useIsMobile } from '@perpay-web/hooks/useIsMobile';
import { useCenterHeaderRender } from '@perpay-web/fintech/hooks/useCenterHeaderContext';
import { useShopPath } from '@perpay-web/fintech/hooks/useShopPath';

import { EndToEndTestIdProvider } from '@perpay-web/utils/endToEndTestIds';
import styles from './DefaultHeader.scss';

const DefaultHeader = ({
    className = '',
    path,
    userData = { fullName: '' },
    isAuthenticated = false,
    isPerpayPlusEligible,
    hasPerpayPlus,
    onClickPerpayPlus,
    launchSupportToolHandler,
    hasClosedCardAccount,
    analyticsHeaderOnClickDashboard,
    analyticsHeaderOnClickShop,
    analyticsHeaderOnClickHamburger,
}) => {
    const [navClicked, setNavClicked] = useState();
    const isMobile = useIsMobile();
    const isAppBrowser = isAppBrowserAgent();
    const location = useLocation();
    const { isLoadingShopPath, lastShopPath } = useShopPath();

    const navClickHandler = () => {
        setNavClicked(!navClicked);
    };

    const determineActive = (key) => {
        if (location.pathname.includes(key.toLowerCase())) {
            return styles['block__text--active'];
        }
        return '';
    };

    const renderDefaultRightAuthNav = () => {
        if (!isAuthenticated) {
            return null;
        }

        return (
            <div className={styles['secondary-navigation__block']}>
                <EndToEndTestIdProvider testId='header-drawer-icon'>
                    <CustomButton
                        action='link'
                        className={styles.profile__block}
                        onClick={() => {
                            navClickHandler();
                            analyticsHeaderOnClickHamburger();
                        }}
                    >
                        <div className={styles.icon__block} />
                    </CustomButton>
                </EndToEndTestIdProvider>
                {navClicked ? (
                    <HeaderDrawerMenu
                        onClose={() => navClickHandler()}
                        fullName={userData.fullName}
                        accountBalance={userData.accountBalance}
                        onClickPerpayPlus={onClickPerpayPlus}
                        launchSupportToolHandler={launchSupportToolHandler}
                        isPerpayPlusEligible={isPerpayPlusEligible}
                        hasClosedCardAccount={hasClosedCardAccount}
                    />
                ) : null}
            </div>
        );
    };

    const rightNavRendered = useHeaderRightNavRender();
    const rightNav =
        typeof rightNavRendered === 'undefined'
            ? renderDefaultRightAuthNav()
            : rightNavRendered;

    const unauthLogoUrl = isAppBrowser ? paths.signUp.path : HOME_PAGE_URL;
    const headerLogoUrl = isAuthenticated ? paths.home.path : unauthLogoUrl;
    const overrideHeaderContainerWidth =
        (!isAuthenticated || path === paths.signUp.path) && !isMobile;

    const renderedCenterHeader = useCenterHeaderRender();
    const renderDefaultCenterHeader = () => {
        if (!isAuthenticated || isLoadingShopPath) {
            return null;
        }

        if (
            focusFlowPaths.some((focusFlowPath) =>
                location.pathname.startsWith(focusFlowPath),
            )
        ) {
            return null;
        }

        return (
            <>
                <CustomLink
                    onClick={analyticsHeaderOnClickDashboard}
                    href={paths.dashboard.path}
                    className={getClassName(
                        styles.block__text,
                        determineActive('dashboard'),
                    )}
                >
                    Dashboard
                </CustomLink>
                <CustomLink
                    onClick={analyticsHeaderOnClickShop}
                    href={lastShopPath}
                    className={getClassName(
                        styles.block__text,
                        determineActive('shop'),
                    )}
                >
                    Shop
                </CustomLink>
            </>
        );
    };

    const centerHeader =
        typeof renderedCenterHeader !== 'undefined'
            ? renderedCenterHeader
            : renderDefaultCenterHeader();

    return (
        <div className={getClassName(className, styles.header)}>
            <div
                className={`
                    ${styles.header__container} \
                    ${
                        overrideHeaderContainerWidth
                            ? styles['unauth--desktop-only']
                            : ''
                    } \
                `}
            >
                {/* Left Nav */}
                <CustomLink
                    className={getClassName(
                        styles.logo__block,
                        styles['link-image'],
                        hasPerpayPlus ? styles['logo--perpay-plus'] : '',
                    )}
                    href={headerLogoUrl}
                >
                    Go to the home page
                </CustomLink>
                {/* Center Nav */}
                <div className={styles['center-nav__container']}>
                    {centerHeader}
                </div>
                {/* Right Nav */}
                {rightNav}
            </div>
        </div>
    );
};

export default DefaultHeader;
