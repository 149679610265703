import * as partnerOnboardingSteps from '@perpay-web/fintech/constants/steps/cardPartnerOnboardingSteps';
import {
    NEW,
    APPROVED,
    REJECTED,
    CLARITY_FROZEN,
    AWAITING_DEDUCTION_SETUP,
    COMPLETE,
    UPLOAD_DOCUMENTS,
    IN_REVIEW,
} from '@perpay-web/fintech/constants/cardAccountApplicationStatuses';
import KarmaLogo from '@perpay-web/fintech/assets/images/inputKarmaLogo.svg';
import CreditLandLogo from '@perpay-web/fintech/assets/images/creditLandLogo.svg';
import BestCreditOffersLogo from '@perpay-web/fintech/assets/images/bestCreditOffersLogo.svg';
import {
    CREDIT_KARMA_LIGHTBOX,
    CREDIT_KARMA_INVITE_TO_APPLY,
    CREDIT_LAND,
    BEST_CREDIT_OFFERS,
} from '@perpay-web/fintech/constants/partnerOnboardingCodes';

export const getPartnerOnboardingStepFromStatus = (status) => {
    switch (status) {
        case NEW:
            return partnerOnboardingSteps.SUBMITTING_STEP;
        case REJECTED:
            return partnerOnboardingSteps.DENIED_STEP;
        case CLARITY_FROZEN:
            return partnerOnboardingSteps.CLARITY_UNFREEZE_STEP;
        case UPLOAD_DOCUMENTS:
            return partnerOnboardingSteps.UPLOAD_DOCUMENTS_STEP;
        case IN_REVIEW:
            return partnerOnboardingSteps.IN_REVIEW_STEP;
        case APPROVED:
            return partnerOnboardingSteps.APPROVED_STEP;
        case AWAITING_DEDUCTION_SETUP:
            return partnerOnboardingSteps.REVIEW_DIRECT_DEPOSIT_STEP;
        case COMPLETE:
            return partnerOnboardingSteps.SUCCESS_STEP;
        default:
            return partnerOnboardingSteps.APPLICATION_ERROR_STEP;
    }
};

export const partnerOnboardingStepperNames = [
    { name: '1. About me' },
    { name: '2. Application' },
    { name: '3. Offer' },
    { name: '4. Direct Deposit' },
];

export const getPartnerOnboardingLogo = (partnerCode) => {
    switch (partnerCode) {
        case CREDIT_KARMA_LIGHTBOX:
        case CREDIT_KARMA_INVITE_TO_APPLY:
            return {
                partnerLogo: KarmaLogo,
                partnerLogoClassName: 'credit-karma',
            };
        case CREDIT_LAND:
            return {
                partnerLogo: CreditLandLogo,
                partnerLogoClassName: 'credit-land',
            };
        case BEST_CREDIT_OFFERS:
            return {
                partnerLogo: BestCreditOffersLogo,
                partnerLogoClassName: 'best-credit-offers',
            };
        default:
            return {
                partnerLogo: null,
                partnerLogoClassName: '',
            };
    }
};

export const getPartnerNameFromCode = (partnerCode) => {
    switch (partnerCode) {
        case CREDIT_KARMA_LIGHTBOX:
            return 'CreditKarma LB';
        case CREDIT_KARMA_INVITE_TO_APPLY:
            return 'CreditKarma ITA';
        case CREDIT_LAND:
            return 'CreditLand';
        case BEST_CREDIT_OFFERS:
            return 'BestCreditOffers';
        default:
            return null;
    }
};
