import {
    BACKEND_CREATE_WITHDRAWAL_REQUEST,
    BACKEND_CREATE_WITHDRAWAL_SUCCESS,
    BACKEND_CREATE_WITHDRAWAL_ERROR,
    BACKEND_FETCH_WITHDRAWAL_BALANCE,
    STORE_REPLACE_WITHDRAWAL_BALANCE,
    STORE_REPLACE_WITHDRAWAL_BALANCE_ERROR,
} from '@perpay-web/fintech/constants/actionTypes';

const initialState = {
    availableCredit: 0,
    errors: {},
    isLoading: false,
    noActiveOrders: true,
    profileComplete: true,
    validBankAccountExists: true,
};

const withdrawalBalanceReducer = (state = initialState, action = {}) => {
    const { type, payload } = action;
    switch (type) {
        case BACKEND_CREATE_WITHDRAWAL_REQUEST:
            return { ...state, submitting: true, errors: {} };
        case BACKEND_CREATE_WITHDRAWAL_SUCCESS:
            return { ...state, submitting: false, ...payload };
        case BACKEND_CREATE_WITHDRAWAL_ERROR:
            return { ...state, submitting: false, errors: payload };
        case BACKEND_FETCH_WITHDRAWAL_BALANCE:
            return { ...state, isLoading: true };
        case STORE_REPLACE_WITHDRAWAL_BALANCE_ERROR:
            return { ...state, isLoading: false, errors: payload };
        case STORE_REPLACE_WITHDRAWAL_BALANCE:
            return {
                ...state,
                isLoading: false,
                validBankAccountExists: payload.validBankAccountExists,
                noActiveOrders: payload.noActiveOrders,
                availableCredit: payload.availableCredit,
                profileComplete: payload.profileComplete,
            };
        default:
            return state;
    }
};

export default withdrawalBalanceReducer;
