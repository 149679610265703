import * as PATHS from '@perpay-web/fintech/constants/paths';
import { MAGENTO_URL } from '@perpay-web/constants/urls';

export const paths = {
    logout: {
        path: PATHS.LOGOUT,
        text: 'Logout',
    },
    withdrawal: {
        path: PATHS.WITHDRAWAL,
        text: 'Withdraw',
    },
    editEmployment: {
        path: PATHS.EDIT_EMPLOYMENT,
        text: 'Edit Employment',
    },
    home: {
        path: PATHS.HOME,
        text: 'Home',
    },
    dashboard: {
        path: PATHS.DASHBOARD,
        text: 'Dashboard',
    },
    orders: {
        path: PATHS.ORDERS,
        text: 'Orders',
    },
    shop: {
        // TODO: remove with AB test tear down https://perpay.atlassian.net/browse/COM-2066
        path: MAGENTO_URL,
        text: 'Shop',
    },
    newShop: {
        path: PATHS.SHOP,
        text: 'Shop',
    },
    marketplaceActivity: {
        path: PATHS.MARKETPLACE_ACTIVITY,
        text: 'Marketplace',
    },
    marketplaceDetails: {
        path: PATHS.MARKETPLACE_DETAILS,
        text: 'Marketplace Details',
    },
    marketplaceLearn: {
        path: PATHS.MARKETPLACE_LEARN,
        text: 'Marketplace Learn',
    },
    profile: {
        path: PATHS.PROFILE,
        text: 'Profile',
    },
    editInfo: {
        path: PATHS.EDIT_INFO,
        text: 'Edit Profile',
    },
    login: {
        path: PATHS.LOGIN,
        text: 'Login',
    },
    resetPassword: {
        path: PATHS.RESET_PASSWORD,
        text: 'Reset Password',
    },
    referral: {
        path: `${PATHS.REFERRALS}:referralCode`,
        text: 'Referral',
    },
    referrals: {
        path: PATHS.REFERRALS,
        text: 'Referrals',
    },
    signUp: {
        path: PATHS.SIGN_UP,
        text: 'Sign Up',
    },
    checkout: {
        path: PATHS.CHECKOUT,
        text: 'Checkout',
    },
    paymentInstructions: {
        path: PATHS.PAYMENT_INSTRUCTIONS,
        text: 'Payment Instructions',
    },
    paymentPortal: {
        path: PATHS.PAYMENT_PORTAL,
        text: 'Payment Portal',
    },
    recurringPayments: {
        path: PATHS.RECURRING_PAYMENTS,
        text: 'Recurring Payments',
    },
    oneTimePayments: {
        path: PATHS.ONE_TIME_PAYMENTS,
        text: 'One Time Payments',
    },
    paymentSetup: {
        path: PATHS.PAYMENT_SETUP,
        text: 'Set Up Payments',
    },
    identityVerification: {
        path: PATHS.IDENTITY_VERIFICATION,
        text: 'Verify Identity',
    },
    bankCreate: {
        path: PATHS.BANK_CREATE,
        text: 'Add Bank',
    },
    bankVerify: {
        path: PATHS.BANK_VERIFY,
        text: 'Verify Bank',
    },
    bankVerifySelect: {
        path: PATHS.BANK_VERIFY_SELECT,
        text: 'Select Bank to Verify',
    },
    externalCardCreate: {
        path: PATHS.EXTERNAL_CARD_CREATE,
        text: 'Add Card',
    },
    uploadPaystub: {
        path: PATHS.UPLOAD_PAYSTUB,
        text: 'Upload Paystub',
    },
    creditReporting: {
        path: PATHS.CREDIT_REPORTING,
        text: 'Activate Credit Reporting',
    },
    creditMonitoring: {
        path: PATHS.CREDIT_MONITORING,
        text: 'Credit Monitoring Summary',
    },
    creditMonitoringDetails: {
        path: PATHS.CREDIT_MONITORING_DETAILS,
        text: 'Score History',
    },
    perpayPlus: {
        path: PATHS.PERPAY_PLUS,
        text: 'Perpay Plus',
    },
    cardInvite: {
        path: PATHS.CARD_INVITE,
        text: 'Card Invite',
    },
    cardActivate: {
        path: PATHS.CARD_ACTIVATE,
        text: 'Activate Card',
    },
    cardOnboarding: {
        path: PATHS.CARD_ONBOARDING,
        text: 'Perpay Credit Card Onboarding',
    },
    cardLearn: {
        path: PATHS.CARD_LEARN,
        text: 'Perpay Credit Card Learn',
    },
    cardDetails: {
        path: PATHS.CARD_DETAILS,
        text: 'Card Details',
    },
    cardActivity: {
        path: PATHS.CARD_ACTIVITY,
        text: 'Card Activity',
    },
    cardPayment: {
        path: PATHS.CARD_PAYMENT,
        text: 'Card Additional Payment',
    },
    cardStatements: {
        path: PATHS.CARD_STATEMENTS,
        text: 'Card Statements',
    },
    cardSettings: {
        path: PATHS.CARD_SETTINGS,
        text: 'Card Settings',
    },
    directDeposit: {
        path: PATHS.DIRECT_DEPOSIT,
        text: 'Direct Deposit',
    },
    updateDirectDeposit: {
        path: PATHS.UPDATE_DIRECT_DEPOSIT,
        text: 'Update Direct Deposit',
    },
    updateDirectDepositCreditLimit: {
        path: PATHS.UPDATE_DIRECT_DEPOSIT_CLI,
        text: 'Update Direct Deposit',
    },
    rewardsAndCredits: {
        path: PATHS.REWARDS_AND_CREDITS,
        text: 'Rewards And Credits',
    },
    rewardsAndCreditsDetails: {
        path: PATHS.REWARDS_AND_CREDITS_DETAILS,
        text: 'Rewards And Credits Details',
    },
    rewardHistory: {
        path: PATHS.REWARD_HISTORY,
        text: 'Reward History',
    },
    directDepositActivity: {
        path: PATHS.DIRECT_DEPOSIT_ACTIVITY,
        text: 'Direct Deposit Activity',
    },
    partnerOnboardingInvitation: {
        path: PATHS.CARD_PARTNER_ONBOARDING_INVITATION,
        text: 'Partner Onboarding',
    },
    partnerOnboardingApplication: {
        path: PATHS.CARD_PARTNER_ONBOARDING_APPLY,
        text: 'Partner Onboarding',
    },
};

export const focusFlowPaths = [
    paths.checkout.path,
    paths.paymentInstructions.path,
    paths.signUp.path,
    paths.paymentPortal.path,
    paths.recurringPayments.path,
    paths.oneTimePayments.path,
    paths.paymentSetup.path,
    paths.identityVerification.path,
    paths.bankCreate.path,
    paths.bankVerify.path,
    paths.externalCardCreate.path,
    paths.referral.path,
    paths.uploadPaystub.path,
    paths.perpayPlus.path,
    paths.bankVerifySelect.path,
    paths.editEmployment.path,
    paths.cardOnboarding.path,
    paths.cardStatements.path,
    paths.cardActivate.path,
    paths.cardPayment.path,
    paths.cardSettings.path,
    paths.updateDirectDeposit.path,
    paths.updateDirectDepositCreditLimit.path,
    paths.rewardsAndCredits.path,
    paths.rewardsAndCreditsDetails.path,
    paths.rewardHistory.path,
];
