import { createDataModule } from '@perpay-web/data-module/createDataModule';
import {
    fetchStorefrontABTest,
    fetchStorefrontABTestSuccess,
    fetchStorefrontABTestError,
    fetchStorefrontABTestReset,
} from '@perpay-web/fintech/actions/entities/storefrontABTest';

const FetchStorefrontABTestDataModule = createDataModule({
    dataRequest: fetchStorefrontABTest,
    dataSuccess: fetchStorefrontABTestSuccess,
    dataError: fetchStorefrontABTestError,
    dataReset: fetchStorefrontABTestReset,
});

const getRoot = (state) => state.dataModules.fetchStorefrontABTest;

const initialValue = {
    isUserInTestGroup: false,
};

export const fetchStorefrontABTestDataModule = FetchStorefrontABTestDataModule({
    getRoot,
    initialValue,
});

export const getIsUserInStorefrontTestGroup = (state) =>
    fetchStorefrontABTestDataModule.getData(state).isUserInTestGroup || false;
