import {
    MAGENTO_FEATURED_AUDIO_URL,
    MAGENTO_FEATURED_HANDBAGS_URL,
    MAGENTO_FEATURED_GAMING_URL,
    MAGENTO_FEATURED_HOUSEHOLD_BASICS_URL,
    MAGENTO_FEATURED_KIDS_URL,
    MAGENTO_FEATURED_HOUSEWARES_URL,
    MAGENTO_FEATURED_KITCHEN_URL,
} from '@perpay-web/fintech/constants/urls';
import {
    REACT_STOREFRONT_AUDIO_PATH,
    REACT_STOREFRONT_HANDBAGS_PATH,
    REACT_STOREFRONT_GAMING_PATH,
    REACT_STOREFRONT_HOUSEHOLD_BASICS_PATH,
    REACT_STOREFRONT_KIDS_PATH,
    REACT_STOREFRONT_HOUSEWARES_PATH,
    REACT_STOREFRONT_KITCHEN_PATH,
} from '@perpay-web/fintech/constants/paths';

export const AUDIO_CATEGORY = 'audio';
export const HANDBAGS_CATEGORY = 'handbags';
export const VIEDO_GAMES_CATEGORY = 'video games';
export const HOUSEHOLD_BASICS_CATEGORY = 'basics';
export const KIDS_CATEGORY = 'toys';
export const HOUSEWARES_CATEGORY = 'housewares';
export const KITCHEN_CATEGORY = 'kitchen';

export const MAGENTO_URLS_BY_CATEGORY = {
    [AUDIO_CATEGORY]: MAGENTO_FEATURED_AUDIO_URL,
    [HANDBAGS_CATEGORY]: MAGENTO_FEATURED_HANDBAGS_URL,
    [VIEDO_GAMES_CATEGORY]: MAGENTO_FEATURED_GAMING_URL,
    [HOUSEHOLD_BASICS_CATEGORY]: MAGENTO_FEATURED_HOUSEHOLD_BASICS_URL,
    [KIDS_CATEGORY]: MAGENTO_FEATURED_KIDS_URL,
    [HOUSEWARES_CATEGORY]: MAGENTO_FEATURED_HOUSEWARES_URL,
    [KITCHEN_CATEGORY]: MAGENTO_FEATURED_KITCHEN_URL,
};

export const REACT_STOREFRONT_PATHS_BY_CATEGORY = {
    [AUDIO_CATEGORY]: REACT_STOREFRONT_AUDIO_PATH,
    [HANDBAGS_CATEGORY]: REACT_STOREFRONT_HANDBAGS_PATH,
    [VIEDO_GAMES_CATEGORY]: REACT_STOREFRONT_GAMING_PATH,
    [HOUSEHOLD_BASICS_CATEGORY]: REACT_STOREFRONT_HOUSEHOLD_BASICS_PATH,
    [KIDS_CATEGORY]: REACT_STOREFRONT_KIDS_PATH,
    [HOUSEWARES_CATEGORY]: REACT_STOREFRONT_HOUSEWARES_PATH,
    [KITCHEN_CATEGORY]: REACT_STOREFRONT_KITCHEN_PATH,
};
