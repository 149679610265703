import { createDataModuleActions } from '@perpay-web/data-module/createDataModuleActions';
import {
    BACKEND_CARD_ACTIVATE,
    STORE_CARD_ACTIVATE_SUCCESS,
    STORE_CARD_ACTIVATE_ERROR,
    STORE_CARD_ACTIVATE_REPLACE_LAST_FOUR,
} from '@perpay-web/fintech/constants/actionTypes';

export const cardActivationActivate = (lastFour, cardUuid) => ({
    type: BACKEND_CARD_ACTIVATE,
    payload: { lastFour, cardUuid },
});

export const cardActivateSuccess = () => ({
    type: STORE_CARD_ACTIVATE_SUCCESS,
});

export const cardActivateError = (errors) => ({
    type: STORE_CARD_ACTIVATE_ERROR,
    payload: errors,
});

export const cardActivationChangeLastFour = (lastFour) => ({
    type: STORE_CARD_ACTIVATE_REPLACE_LAST_FOUR,
    payload: { lastFour },
});

const fetchActivationActions = createDataModuleActions('FETCH_CARD_ACTIVATION');
export const fetchCardActivation = fetchActivationActions.dataRequest;
export const fetchCardActivationSuccess = fetchActivationActions.dataSuccess;
export const fetchCardActivationError = fetchActivationActions.dataError;
export const fetchCardActivationReset = fetchActivationActions.dataReset;
