import emitter from '@marvelapp/react-ab-test/lib/emitter';
import { backendABTestMap } from '@perpay-web/constants/experiments';

let experimentsDefined = false;
const experimentVariantMap = {};

export function getOrCreateExperimentValue(name) {
    if (window.STORYBOOK) {
        return '';
    }
    if (!experimentsDefined) {
        return '';
    }

    const existingVariant = emitter.getActiveVariant(name);
    if (existingVariant) {
        return existingVariant;
    }

    const variant = emitter.calculateActiveVariant(name);
    return variant;
}

// Infrequently used, but used often enough where we want to keep in the repo
// eslint-disable-next-line import/no-unused-modules
export function reportExperimentVariant(name) {
    const variant = getOrCreateExperimentValue(name);
    // It's ok to ignore this linter rule because the library doesn't expose this method
    // publicly, but it really should have : )
    // eslint-disable-next-line no-underscore-dangle
    emitter._emitPlay(name, variant);
}

// Infrequently used, but used often enough where we want to keep in the repo
export function defineExperiment(name, variants, weights) {
    emitter.defineVariants(name, variants, weights);
    experimentVariantMap[name] = variants;
    experimentsDefined = true;
}

export function getExperimentSet() {
    const experimentSet = [];

    // Frontend AB Tests
    Object.keys(experimentVariantMap).forEach((experiment) => {
        const activeVariant = emitter.getActiveVariant(experiment);
        const experimentData = {
            experiment,
            active_variant: activeVariant,
            type: 'frontend',
        };
        experimentSet.push(experimentData);
    });

    // Backend AB Tests
    Object.keys(backendABTestMap).forEach((experiment) => {
        const experimentName = backendABTestMap[experiment];
        const experimentData = {
            experiment: experimentName,
            type: 'backend',
        };
        experimentSet.push(experimentData);
    });

    return experimentSet;
}
