import { lazy } from 'react';
import { Route } from 'react-router-dom';

import { withRedirectIfAuthenticated } from '@perpay-web/components/higher-order/WithRedirectIfAuthenticated/WithRedirectIfAuthenticated';
import { withLoader } from '@perpay-web/components/higher-order/WithLoader/WithLoader';
import { paths } from '@perpay-web/fintech/props/appPaths';
import { importReferralLandingContainer } from '@perpay-web/fintech/utils/imports/importReferralLandingContainer';
import { compose } from '@perpay-web/utils/functionalUtils';
import { withBottomNav } from '@perpay-web/components/higher-order/WithBottomNav/WithBottomNav';

const EmptyBottomNav = () => null;
const ReferralLandingRoute = compose(
    withBottomNav(EmptyBottomNav),
    withRedirectIfAuthenticated,
)(withLoader(lazy(importReferralLandingContainer)));

export const renderUnauthenticatedRoutes = () => [
    <Route path={paths.referral.path} Component={ReferralLandingRoute} />,
];
