import { Component } from 'react';
import PropTypes from 'prop-types';
import { reportError } from '@perpay-web/utils/errorHandlers';
import { paths } from '@perpay-web/fintech/props/appPaths';
import CustomLink from '@perpay-web/fintech/components/base/CustomLink/CustomLink';
import styles from './ErrorBoundary.scss';

export class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    componentDidCatch(error) {
        this.setState({ hasError: true, error });
        reportError(error);
    }

    render() {
        const { children, ErrorComponent, ...rest } = this.props;

        if (this.state.hasError) {
            return <ErrorComponent {...rest} error={this.state.error} />;
        }
        return this.props.children;
    }
}

export const ComponentLevelErrorComponent = ({ error }) => (
    <div className={styles['default-error-boundary']}>
        <p>
            <span role='img' aria-label='Oops'>
                😭
            </span>
            Oops! We encountered an error. Try again later?
        </p>
        <CustomLink href={paths.dashboard.path}>
            Return to the dashboard
        </CustomLink>
        {!window.PRODUCTION ? (
            <>
                <p>{error.message}</p>
                <pre className={styles['error-stack']}>{error.stack}</pre>
            </>
        ) : null}
    </div>
);

ComponentLevelErrorComponent.propTypes = {
    error: PropTypes.instanceOf(Error).isRequired,
};

export const PageLevelErrorComponent = ({ error }) => (
    <div className={styles['default-error-boundary']}>
        <span className={styles['oops-emoji']} role='img' aria-label='Oops'>
            😭
        </span>
        <p>Oops! We encountered an error.</p>
        <a href='/'>Return to the home page</a>
        {!window.PRODUCTION ? (
            <>
                <p>{error.message}</p>
                <pre className={styles['error-stack']}>{error.stack}</pre>
            </>
        ) : null}
    </div>
);

PageLevelErrorComponent.propTypes = {
    error: PropTypes.instanceOf(Error).isRequired,
};

ErrorBoundary.propTypes = {
    ErrorComponent: PropTypes.elementType.isRequired,
    children: PropTypes.node.isRequired,
};
