import { lazy } from 'react';
import { hideKeyWarning } from '@perpay-web/utils/componentUtils';
import { createRoutesFromElements, Route } from 'react-router-dom';
import { importStorefront } from '@perpay-web/fintech/utils/imports/importStorefront';
import { RedirectFallbackContainer } from '@perpay-web/fintech/components/containers/RedirectFallbackContainer';
import { getReducerManager } from '@perpay-web/fintech/reducers/reducers';
import { getEpicManager } from '@perpay-web/fintech/epics/epics';
import { getOrCreateRouter } from '@perpay-web/services/router';
import AppContainer from '@perpay-web/fintech/components/containers/AppContainer';
import MagentoRedirect from '@perpay-web/fintech/components/composite/MagentoRedirect/MagentoRedirect';
import ProductQueryRedirect from '@perpay-web/components/base/ProductQueryRedirect/ProductQueryRedirect';
import { analyticsLocationChange } from '@perpay-web/hooks/useTrackLocationChange';
import { withFullscreenLoader } from '@perpay-web/components/higher-order/WithLoader/WithLoader';
import { paths as storefrontPaths } from '@perpay-web/storefront/props/appPaths';
import { SHOP_PREFIX } from '@perpay-web/constants/paths';
import store from '@perpay-web/fintech/settings/configureStore';
import {
    clearLastReactStorefrontPage,
    pathIsInReactStorefront,
    setLastReactStorefrontPage,
} from '@perpay-web/utils/routeUtils';
import { renderAuthenticatedRoutes } from './AuthenticatedRoutes';
import { renderAuthenticatingRoutes } from './AuthenticatingRoutes';
import { renderUnauthenticatedRoutes } from './UnauthenticatedRoutes';

const LazyStorefrontApp = withFullscreenLoader(
    lazy(() => {
        const importPromise = importStorefront();

        importPromise.then(({ StorefrontUnit }) => {
            StorefrontUnit(getEpicManager(), getReducerManager());
        });

        return importPromise;
    }),
);

const getMagentoRedirectRoutes = () =>
    Object.values(storefrontPaths)
        .filter((path) => Boolean(path.magentoPath))
        .map((path) => (
            <Route
                path={`${SHOP_PREFIX}${path.magentoPath}`}
                element={
                    <MagentoRedirect
                        storefrontPath={path.path}
                        magentoRewrite={path.magentoRewrite}
                    />
                }
            />
        ));

const createRouter = () =>
    getOrCreateRouter(
        createRoutesFromElements([
            <Route path='/shop/*' Component={LazyStorefrontApp} />,
            <Route path='/' Component={AppContainer}>
                {hideKeyWarning([
                    ...renderAuthenticatedRoutes(),
                    ...renderAuthenticatingRoutes(),
                    ...renderUnauthenticatedRoutes(),
                    ...getMagentoRedirectRoutes(),
                    <Route
                        path={storefrontPaths.productDetailsRoot.path}
                        element={<ProductQueryRedirect />}
                    />,
                    <Route path='' element={<RedirectFallbackContainer />} />,
                ])}
                <Route path='*' element={<RedirectFallbackContainer />} />,
            </Route>,
        ]),
        // On initialization, dispatch location change once
        (router) => {
            store.dispatch(
                analyticsLocationChange({
                    location: router.state.location,
                    action: 'POP',
                }),
            );
            clearLastReactStorefrontPage();
            if (pathIsInReactStorefront(router.state.location.pathname))
                setLastReactStorefrontPage(
                    router.state.location.pathname,
                    router.state.location.search,
                );
        },
    );

// imported directly in the root file
// eslint-disable-next-line import/no-unused-modules
export default createRouter();
