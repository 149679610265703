import {
    BACKEND_FETCH_JOBS,
    STORE_REPLACE_JOBS,
    STORE_REPLACE_JOBS_ERROR,
} from '@perpay-web/fintech/constants/actionTypes';

const initialState = {
    loading: false,
};

const jobReducer = (state = initialState, action) => {
    const { type } = action;
    switch (type) {
        case BACKEND_FETCH_JOBS:
            return { ...state, loading: true };
        case STORE_REPLACE_JOBS:
            return { ...state, loading: false };
        case STORE_REPLACE_JOBS_ERROR:
            return { ...state, loading: false };
        default:
            return state;
    }
};

export default jobReducer;
