import { createDataModuleActions as createDataModuleActionsUtil } from '@perpay-web/data-module/createDataModuleActions';
import {
    BACKEND_FETCH_FEATURE_ENROLLMENTS,
    STORE_REPLACE_FEATURE_ENROLLMENTS,
    STORE_FETCH_FEATURE_ENROLLMENTS_ERROR,
    BACKEND_FEATURES_ENROLL,
    BACKEND_FEATURES_REENROLL,
    BACKEND_FEATURES_REENABLE,
    BACKEND_FEATURES_DISABLE,
    STORE_FEATURES_ENROLL_ERROR,
    STORE_FEATURES_ENROLL_SUCCESS,
} from '@perpay-web/fintech/constants/actionTypes';

const fetchDataModuleActions = createDataModuleActionsUtil(
    'FETCH_FEATURE_ENROLLMENTS',
);
export const fetchFeatureEnrollments = fetchDataModuleActions.dataRequest;
export const fetchFeatureEnrollmentsSuccess =
    fetchDataModuleActions.dataSuccess;
export const fetchFeatureEnrollmentsError = fetchDataModuleActions.dataError;
export const fetchFeatureEnrollmentsReset = fetchDataModuleActions.dataReset;

const createDataModuleActions = createDataModuleActionsUtil(
    'CREATE_FEATURE_ENROLLMENT',
);
export const createFeatureEnrollment = createDataModuleActions.dataRequest;
export const createFeatureEnrollmentSuccess =
    createDataModuleActions.dataSuccess;
export const createFeatureEnrollmentError = createDataModuleActions.dataError;
export const createFeatureEnrollmentReset = createDataModuleActions.dataReset;

export const enrollFeature = (feature) => ({
    type: BACKEND_FEATURES_ENROLL,
    payload: { feature },
});

// eslint-disable-next-line import/no-unused-modules
export const reEnrollFeature = (feature) => ({
    type: BACKEND_FEATURES_REENROLL,
    payload: { feature },
});

export const reEnableFeature = (feature) => ({
    type: BACKEND_FEATURES_REENABLE,
    payload: { feature },
});

export const disableFeature = (feature) => ({
    type: BACKEND_FEATURES_DISABLE,
    payload: { feature },
});

export const enrollFeatureSuccess = (featureEnrollment) => ({
    type: STORE_FEATURES_ENROLL_SUCCESS,
    payload: featureEnrollment,
});

export const enrollFeatureError = (error) => ({
    type: STORE_FEATURES_ENROLL_ERROR,
    payload: error,
});

export const fetchEnrolledFeatures = () => ({
    type: BACKEND_FETCH_FEATURE_ENROLLMENTS,
});

export const replaceEnrolledFeatures = (featureEnrollments) => ({
    type: STORE_REPLACE_FEATURE_ENROLLMENTS,
    payload: featureEnrollments,
});

export const fetchEnrolledFeaturesError = (error) => ({
    type: STORE_FETCH_FEATURE_ENROLLMENTS_ERROR,
    payload: error,
});
