import { replace } from '@perpay-web/services/router';
import { useMount } from '@perpay-web/hooks/useMount';
import { SHOP_PREFIX } from '@perpay-web/constants/paths';

const ProductQueryRedirect = () => {
    const params = new URLSearchParams(window.location.search);

    useMount(() => {
        const id = params.get('id');
        if (id) {
            params.delete('id');
            replace(`${SHOP_PREFIX}/product/${id}`);
        } else {
            replace(SHOP_PREFIX);
        }
    });
};

export default ProductQueryRedirect;
