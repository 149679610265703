import {
    BACKEND_FETCH_CREDIT_SCORES_SUMMARY,
    STORE_REPLACE_CREDIT_SCORES_SUMMARY,
    STORE_FETCH_CREDIT_SCORES_SUMMARY_ERROR,
    BACKEND_FETCH_FEATURES,
    STORE_REPLACE_FEATURES,
    BACKEND_FETCH_FEATURE_ENROLLMENTS,
    STORE_REPLACE_FEATURE_ENROLLMENTS,
    STORE_FETCH_FEATURE_ENROLLMENTS_ERROR,
    STORE_FETCH_FEATURES_ERROR,
    BACKEND_FETCH_USER_STATUS,
    STORE_REPLACE_USER_STATUS,
    STORE_REPLACE_USER_STATUS_ERROR,
} from '@perpay-web/fintech/constants/actionTypes';

const initialState = {
    fetchingFeatures: true,
    fetchingFeatureEnrollments: true,
    fetchingCreditScoresSummary: true,
    fetchingUserStatus: false,
    errors: {},
};

const creditMonitoringReducer = (state = initialState, action) => {
    switch (action.type) {
        case BACKEND_FETCH_FEATURES:
            return { ...state, fetchingFeatures: true, errors: {} };
        case STORE_REPLACE_FEATURES:
            return { ...state, fetchingFeatures: false };
        case STORE_FETCH_FEATURES_ERROR:
            return {
                ...state,
                fetchingFeatures: false,
                errors: action.payload,
            };
        case BACKEND_FETCH_CREDIT_SCORES_SUMMARY:
            return { ...state, fetchingCreditScoresSummary: true, errors: {} };
        case STORE_REPLACE_CREDIT_SCORES_SUMMARY:
            return { ...state, fetchingCreditScoresSummary: false };
        case STORE_FETCH_CREDIT_SCORES_SUMMARY_ERROR: {
            return {
                ...state,
                fetchingCreditScoresSummary: false,
                errors: action.payload,
            };
        }
        case BACKEND_FETCH_USER_STATUS: {
            return { ...state, fetchingUserStatus: true };
        }
        case STORE_REPLACE_USER_STATUS: {
            return { ...state, fetchingUserStatus: false };
        }
        case STORE_REPLACE_USER_STATUS_ERROR: {
            return { ...state, fetchingUserStatus: false };
        }
        case BACKEND_FETCH_FEATURE_ENROLLMENTS: {
            return { ...state, fetchingFeatureEnrollments: true };
        }
        case STORE_REPLACE_FEATURE_ENROLLMENTS:
        case STORE_FETCH_FEATURE_ENROLLMENTS_ERROR:
            return { ...state, fetchingFeatureEnrollments: false };
        default:
            return state;
    }
};

export default creditMonitoringReducer;
