import stateCodes from '@perpay-web/constants/stateCodes';

export const stateStringToStateCode = (stateString) => {
    const stateStringToStateCodesMap = {};
    stateCodes.forEach((stateCode) => {
        const { value, label } = stateCode;
        stateStringToStateCodesMap[label] = value;
    });
    return stateStringToStateCodesMap[stateString];
};

export const recommendedAddressSameAsOriginal = (
    validatedAddress,
    originalAddress,
) =>
    validatedAddress.zipcode === originalAddress.zipcode &&
    validatedAddress.state === originalAddress.state &&
    validatedAddress.city.toLowerCase() ===
        originalAddress.city.toLowerCase() &&
    (validatedAddress.street1.toLowerCase() ===
        originalAddress.street1.toLowerCase() ||
        validatedAddress.street1.toLowerCase().replace(' st', '') ===
            originalAddress.street1.toLowerCase().replace(' street', '')) &&
    (originalAddress.street2 === undefined ||
        validatedAddress.street2.replace('# ', '').toLowerCase() ===
            originalAddress.street2.toLowerCase());

// Returns the initial five digits of a zipcode
export const zipCodeFirstFive = (zipcode) => zipcode.slice(0, 5);
