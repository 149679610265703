import { useReducer, useLayoutEffect, useRef, useCallback } from 'react';
import { usePrevious } from '@perpay-web/hooks/usePrevious';
import { createSlice } from '@reduxjs/toolkit';

const getComponentStackIndex = (stack, renderCb) => {
    const index = stack.indexOf(renderCb);
    if (index !== stack.lastIndexOf(renderCb)) {
        console.warn(
            'POTENTIAL BUG: found multiple component stack items with same value',
        );
    }
    return index;
};

const componentStackSlice = createSlice({
    name: 'component-stack',
    initialState: [],
    reducers: (create) => ({
        push: create.reducer((stack, action) => {
            stack.unshift(action.payload.renderCb);
        }),
        remove: create.reducer((stack, action) => {
            const index = getComponentStackIndex(
                stack,
                action.payload.renderCb,
            );
            if (index !== -1) {
                stack.splice(index, 1);
            }
        }),
        replace: create.reducer((stack, action) => {
            const index = getComponentStackIndex(
                stack,
                action.payload.prevRenderCb,
            );
            if (index !== -1) {
                stack.splice(index, 1, action.payload.renderCb);
            }
        }),
    }),
});

const { actions, getInitialState, reducer } = componentStackSlice;

const useComponentStackState = () => {
    const [stack, dispatch] = useReducer(reducer, getInitialState());

    const push = useCallback(
        (renderCb) => dispatch(actions.push({ renderCb })),
        [],
    );
    const remove = useCallback(
        (renderCb) => dispatch(actions.remove({ renderCb })),
        [],
    );
    const replace = useCallback(
        (prevRenderCb, renderCb) =>
            dispatch(actions.replace({ prevRenderCb, renderCb })),
        [],
    );

    return {
        stack,
        push,
        remove,
        replace,
    };
};

export const componentStackProviderFactory = (Context) => {
    /**
     * The component stack provider exposes the component stack state through the factory's
     * ComponentStackContext context, as well as exposing the callbacks to dispatch actions
     * to the reducer to mutate the component stack state.
     * Storing this information in context allows us to avoid adding non-serializable data
     * to the redux store, but this operates in much the same way as if it was stored in Redux.
     */
    const ComponentStackProvider = ({ children }) => {
        const hook = useComponentStackState();

        return <Context.Provider value={hook}>{children}</Context.Provider>;
    };

    return ComponentStackProvider;
};

const renderComponentStackValue = (value, props) =>
    typeof value === 'function' ? value(props) : value;

/**
 * The `useComponentStack` hook allows components like a flow or pages within a flow
 * to override UI rendered above them in the component hierarchy. The UI is rendered
 * on mount and is removed when they unmount. If a parent and child element both use the hook,
 * the parent will override the child's value.
 *
 * The render function parameter must be from a useCallback hook. This prevents
 * a raw arrow function from triggering an infinite loop in the useEffect below.
 *
 * Functions used in the render method SHOULD ALMOST ALWAYS be passed to useCallback
 * and then used by the render function. This prevents infinite rerenders.
 *
 * For each separate out-of-context render site, it is recommended to create an individual
 * custom hook for that rather than exporting and importing the context. It's just cleaner that way.
 *
 * Sample Usage:
 * ```javascript
 * // useHeader.js
 * const HeaderContext = React.createContext();
 * export const Provider = createComponentStackProvider(HeaderContext);
 * export const useHeaderRender = () => useComponentStackRender(HeaderContext);
 * export const useHeader = (renderCb) = useComponentStack(HeaderContext, renderCb);
 *
 * // index.js
 * render(
 *     <Provider>
 *         <App />
 *     </Provider>,
 *     document.getElementById('root')
 * );
 *
 * // Header.js
 * const Header => () => {
 *   const headerContent = useHeaderRender();
 *
 *   return (
 *     <div className="header">
 *         <Logo />
 *         <Message />
 *         {typeof headerContent === 'undefined' ? <HamburgerMenu /> : headerContent}
 *     </div>
 *   );
 * };
 *
 * // MyPageComponent.js
 * const MyPageComponent = ({ doFoo }) => {
 *   const doFooCb = useConstCallback(doFoo);
 *   const renderHeaderCb = useCallback(() => (
 *     <button onClick={doFooCb}>Back</button>
 *   ), [doFooCb]);
 *   useHeader(renderHeaderCb);
 *
 *   return (
 *     <div>This page is the best!</div>
 *   );
 * };
 * ```
 */
export const useComponentStack = (componentStackContext, renderCb) => {
    const { push, remove, replace } = componentStackContext;
    const previousRenderCb = usePrevious(renderCb);
    const isFirstRenderRef = useRef(true);

    // We must store and update `renderCb` into a ref so that the correct `renderCb` gets passed to
    // `remove` on unmount. If we called `remove(renderCb)` instead of `remove(renderCbRef.current)`
    // it would have the value from the first render instead of the from the last render.
    // Hooks are nuts, man.
    // Example of someone else experiencing the same issue:
    // https://stackoverflow.com/questions/58646797/react-hook-use-effect-on-component-unmount-only-not-when-dependency-updates
    const renderCbRef = useRef(renderCb);

    useLayoutEffect(() => {
        push(renderCb);
        return () => remove(renderCbRef.current);
    }, [push, remove, renderCb]);

    useLayoutEffect(() => {
        // Do not run replace if we are initializing
        if (isFirstRenderRef.current) {
            isFirstRenderRef.current = false;
            return;
        }

        // Do not run replace if the value is the same
        if (previousRenderCb === renderCb) {
            return;
        }

        // Update the renderCb ref so that unmount has a reference to the latest renderCb
        renderCbRef.current = renderCb;

        replace(previousRenderCb, renderCb);
    }, [replace, renderCb, previousRenderCb]);
};

/**
 * The `useComponentStackRender` hook exposes the component stack to the site where its value
 * can be consumed. For example, if a header component wants to allow other components in the
 * app to render content into a slot that the Header controls, then it can get that value via
 * useComponentStackRender and the other components can set the value via useComponentStack.
 *
 * See the doc comment above for usage.
 */
export const useComponentStackRender = (componentStackContext, props) => {
    const { stack } = componentStackContext;
    const [value] = stack;
    return renderComponentStackValue(value, props);
};
