import {
    componentStackProviderFactory,
    useComponentStack,
    useComponentStackRender,
} from '@perpay-web/hooks/useComponentStack';
import { createContextProvider } from '@perpay-web/utils/componentUtils';

const [CenterHeaderComponentStackProvider, useCenterHeaderContext] =
    createContextProvider(componentStackProviderFactory);

export { CenterHeaderComponentStackProvider };

// TODO: consume in focusFlowPath components
// eslint-disable-next-line import/no-unused-modules
export const useCenterHeader = (renderCb) =>
    useComponentStack(useCenterHeaderContext(), renderCb);

export const useCenterHeaderRender = () =>
    useComponentStackRender(useCenterHeaderContext());
