import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    routeToShop as routeToShopAction,
    routeToLocation,
} from '@perpay-web/fintech/actions/router';
import {
    fetchStorefrontABTestDataModule,
    getIsUserInStorefrontTestGroup,
} from '@perpay-web/fintech/dataModules/fetchStorefrontABTest';
import { getLastReactStorefrontPage } from '@perpay-web/utils/routeUtils';
import { paths } from '@perpay-web/fintech/props/appPaths';
import {
    REACT_STOREFRONT_HOME,
    REACT_STOREFRONT_SEARCH_PATH,
} from '@perpay-web/fintech/constants/paths';
import {
    MAGENTO_HOME_URL,
    MAGENTO_SEARCH_URL,
} from '@perpay-web/fintech/constants/urls';
import {
    MAGENTO_URLS_BY_CATEGORY,
    REACT_STOREFRONT_PATHS_BY_CATEGORY,
} from '@perpay-web/fintech/constants/shopCategories';

export const useShopPath = () => {
    const dispatch = useDispatch();

    const isUserInStorefrontTestGroup = useSelector(
        getIsUserInStorefrontTestGroup,
    );
    const isLoadingShopPath = useSelector(
        fetchStorefrontABTestDataModule.getIsLoadingOrUnrequested,
    );

    const shopPath = isUserInStorefrontTestGroup
        ? paths.newShop.path
        : paths.shop.path;

    const lastShopPath = isUserInStorefrontTestGroup
        ? getLastReactStorefrontPage() || paths.newShop.path
        : paths.shop.path;

    const getCategoryHref = useCallback(
        (category) => {
            if (isUserInStorefrontTestGroup) {
                return REACT_STOREFRONT_PATHS_BY_CATEGORY[category];
            }
            return MAGENTO_URLS_BY_CATEGORY[category];
        },
        [isUserInStorefrontTestGroup],
    );

    const redirectToSearch = useCallback(
        (query) => {
            if (isUserInStorefrontTestGroup) {
                const path =
                    query && query.trim().length > 0
                        ? REACT_STOREFRONT_SEARCH_PATH.replace(':query', query)
                        : REACT_STOREFRONT_HOME;
                dispatch(routeToLocation(path));
            } else {
                const url = new URL(MAGENTO_SEARCH_URL);
                const newUrl =
                    query && query.trim().length > 0
                        ? `${url}${query.trim()}`
                        : MAGENTO_HOME_URL;
                dispatch(routeToShopAction(newUrl));
            }
        },
        [isUserInStorefrontTestGroup, dispatch],
    );

    const routeToShop = useCallback(() => {
        dispatch(routeToLocation(shopPath));
    }, [dispatch, shopPath]);

    return {
        isLoadingShopPath,
        shopPath,
        lastShopPath,
        isReactStorefrontUser: isUserInStorefrontTestGroup,
        getCategoryHref,
        redirectToSearch,
        routeToShop,
    };
};
