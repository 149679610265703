import { defer, throwError, EMPTY } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { catchError, ignoreElements, mergeMap } from 'rxjs/operators';
import {
    RESET_PASSWORD_ENDPOINT,
    RECAPTCHA_ACTIVE_ENDPOINT,
    APP_VERSION_FILE_URL,
    VERIFY_CARD_INVITE_CODE_ENDPOINT,
} from '@perpay-web/fintech/constants/urls';
import { authentication } from '@perpay-web/fintech/settings/singletons';
import { authenticatedJsonFetch as authenticatedJsonFetchOriginal } from '@perpay-web/authentication/authenticatedJsonFetch';
import { jsonFetch as jsonFetchOriginal } from '@perpay-web/utils/requestUtils';
import { HTTPMethod } from '@perpay-web/constants/httpMethods';

const whitelistUrls = [
    RESET_PASSWORD_ENDPOINT,
    RECAPTCHA_ACTIVE_ENDPOINT,
    APP_VERSION_FILE_URL,
    VERIFY_CARD_INVITE_CODE_ENDPOINT,
];

const configureHeaders = (body) => {
    const defaultHeaders = {
        'X-Referer': window.location.href,
        'X-Client-Version': window.VERSION,
    };
    if (window.WITH_CREDENTIALS) {
        defaultHeaders['X-Requested-With'] = 'XMLHttpRequest';
    }
    if (!(body instanceof window.FormData)) {
        // Per https://github.com/ReactiveX/rxjs/pull/1746
        // if the request is for FormData, we should not set the content type header
        defaultHeaders['Content-Type'] = 'application/json';
    }
    return defaultHeaders;
};

/**
 * Promises are eager by default, meaning they do not wait for consumers to call `then`
 * before executing their inner logic. Observables are lazy by default. To convert a Promise
 * to an Observable, we should match the Observable execution model. To make the Promise lazy
 * like Observables, we must wrap the Promise getter in a `defer` so the promiseGetter is
 * only called after the Observable is subscribed.
 */
const promiseToObservable = (promiseGetter) => defer(() => promiseGetter());

// Only visible for testing
// eslint-disable-next-line import/no-unused-modules
export const request = (method, url, body, config) => {
    const headers = configureHeaders(body);
    const urlWithoutQueryParams = url.split('?')[0];

    // Shorthand wrapper for the ajax call.
    // `withCredentials` is needed for cookies to be received and set
    // https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/withCredentials
    const doRequest = (override) =>
        ajax({
            method,
            url,
            headers,
            body,
            withCredentials: window.WITH_CREDENTIALS,
            ...config,
            ...override,
        }).pipe(
            catchError((e) => {
                if (
                    e.status === 401 &&
                    (e.response.message[0] === 'Token is invalid or expired' ||
                        e.response.message[0] === 'Token is blacklisted')
                ) {
                    authentication.logout();
                    return EMPTY;
                }
                return throwError(e);
            }),
        );

    // Before making any requests, we have a few possible scenarios:
    // 1) Check the Whitelist & make request without JWT
    // 2) Check whether the access token is valid & make request
    // 3) Check whether the refresh token is valid & make request
    // 4) If none of the above apply, we log the user out and need them to log in again.

    if (whitelistUrls.includes(urlWithoutQueryParams)) {
        return doRequest();
    }

    if (!authentication.getIsAccessTokenExpired()) {
        return doRequest({
            headers: {
                ...headers,
                AUTHORIZATION: `Bearer ${authentication.getAccessToken()}`,
            },
        });
    }

    if (!authentication.getIsRefreshTokenExpired()) {
        return promiseToObservable(() => authentication.refresh()).pipe(
            mergeMap(() =>
                doRequest({
                    headers: {
                        ...headers,
                        // We wrap this in a defer so that getAccessToken
                        // is called only after the refresh$ completes.
                        AUTHORIZATION: `Bearer ${authentication.getAccessToken()}`,
                    },
                }),
            ),
        );
    }

    // If we get here, an epic initiated a request.
    // We need to interrupt that epic's pipeline so it doesn't continue on with the logout response
    // when the pipeline only needs to expect the success response (outside of the handleError)
    const logout$ = promiseToObservable(() => authentication.logout());
    return logout$.pipe(ignoreElements()); // We don't care about the logout response
};

export const get = (url, config) =>
    request(HTTPMethod.get, url, undefined, config);

export const post = (url, body, config) =>
    request(HTTPMethod.post, url, body, config);

export const put = (url, body, config) =>
    request(HTTPMethod.put, url, body, config);

export const patch = (url, body, config) =>
    request(HTTPMethod.patch, url, body, config);

export const authenticatedJsonFetch = (url, body, method, config = {}) =>
    authenticatedJsonFetchOriginal(url, body, method, config.headers);

export const jsonFetch = (
    url,
    body,
    method,
    config = {},
    credentials = 'same-origin',
) => jsonFetchOriginal(url, body, method, config.headers, credentials);
