import { composeDataModules } from '@perpay-web/data-module/composeDataModules';
import {
    fetchCardAccountsDataModule,
    getCardAccountStatus,
    getHasCardAccount,
} from '@perpay-web/fintech/dataModules/fetchCardAccounts';
import { fetchAutoPaySchedulesDataModule } from '@perpay-web/fintech/dataModules/fetchAutoPaySchedules';
import { fetchInitiatedAutoPayPaymentsDataModule } from '@perpay-web/fintech/dataModules/fetchInitiatedAutoPayPayments';
import { fetchBankAccountsDataModule } from '@perpay-web/fintech/dataModules/fetchBankAccounts';
import { fetchCardActivityDataModule } from '@perpay-web/fintech/dataModules/fetchCardActivity';
import { gateCardAccountDataModule } from '@perpay-web/fintech/dataModules/gateCardAccount';

const dataModules = [
    fetchCardAccountsDataModule,
    fetchAutoPaySchedulesDataModule,
    fetchInitiatedAutoPayPaymentsDataModule,
    fetchBankAccountsDataModule,
    gateCardAccountDataModule,
    fetchCardActivityDataModule,
];

const FetchCardDetailsDataModule = composeDataModules(dataModules);

const getRoot = (state) => state.dataModules.fetchCardDetailsV2;

export const fetchCardDetailsV2DataModule = FetchCardDetailsDataModule({
    getRoot,
    key: 'FETCH_CARD_DETAILS_V2',
});

export { getCardAccountStatus, getHasCardAccount };
