import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useMount } from '@perpay-web/hooks/useMount';
import { LOGIN_PATH } from '@perpay-web/authentication/constants/paths';
import { authentication } from '@perpay-web/authentication/singleton';
import WarningLoaderCircle from '@perpay-web/components/base/WarningLoaderCircle/WarningLoaderCircle';

const useRedirectIfUnauthenticated = () => {
    const navigate = useNavigate();
    const [isLoadingRedirect, setIsLoadingRedirect] = useState(true);

    useMount(() => {
        const params = new URLSearchParams(window.location.search);
        const postLoginPath = params.get('next') || window.location.pathname;
        params.set('next', postLoginPath);

        if (!authentication.getIsAuthenticated()) {
            navigate(`${LOGIN_PATH}?${params}`, { replace: true });
            return;
        }

        setIsLoadingRedirect(false);
    });

    return {
        isLoadingRedirect,
    };
};

export function withRedirectIfUnauthenticated(Component) {
    return (props) => {
        const { isLoadingRedirect } = useRedirectIfUnauthenticated();

        if (isLoadingRedirect) {
            return <WarningLoaderCircle />;
        }

        return <Component {...props} />;
    };
}
