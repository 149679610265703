import { wrapRetry } from '@perpay-web/utils/importUtils';

// eslint-disable-next-line import/no-unused-modules
export const importCardLearnContainer = () =>
    wrapRetry(
        () =>
            import(
                /* webpackChunkName: "importCardLearnContainer" */
                '@perpay-web/fintech/components/containers/CardLearnContainer'
            ),
    );
