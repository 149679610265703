import { ofType } from 'redux-observable';
import { mergeMap } from 'rxjs/operators';
import { ANALYTICS_IDENTIFY_USER } from '@perpay-web/fintech/constants/actionTypes';
import { getCordovaApp } from '@perpay-web/fintech/utils/cordovaUtils';

export const initCordovaOnIdentify = (action$) =>
    action$.pipe(
        ofType(ANALYTICS_IDENTIFY_USER),
        mergeMap((action) => {
            // eslint-disable-next-line camelcase
            const { email, first_name, created } = action.payload;
            getCordovaApp().then((CordovaApp) => {
                CordovaApp.setUser(email, first_name, created);
            });
            return [];
        }),
    );
