import { ofType } from 'redux-observable';
import { switchMap, mergeMap } from 'rxjs/operators';
import { handleError } from '@perpay-web/observable/operators/handleError';
import {
    fetchStorefrontABTest as fetchStorefrontABTestAction,
    fetchStorefrontABTestSuccess,
    fetchStorefrontABTestError,
} from '@perpay-web/fintech/actions/entities/storefrontABTest';
import { STOREFRONT_AB_TEST_ENDPOINT } from '@perpay-web/constants/urls';

export const fetchStorefrontABTest = (action$, state$, { get }) =>
    action$.pipe(
        ofType(fetchStorefrontABTestAction().type),
        switchMap(() => get(STOREFRONT_AB_TEST_ENDPOINT)),
        mergeMap((results) => [fetchStorefrontABTestSuccess(results.response)]),
        handleError((error) => [fetchStorefrontABTestError(error)]),
    );
