import { dollarToCents } from '@perpay-web/utils/numberUtils';
import {
    BACKEND_CREATE_PRIMARY_JOB,
    BACKEND_UPDATE_SALARY_INFO,
    BACKEND_CREATE_PHONE,
    BACKEND_SIGNUP,
    BACKEND_VERIFY_PHONE,
    BACKEND_VERIFY_CARD_INVITE_CODE,
    STORE_CREATE_PRIMARY_JOB_ERROR,
    STORE_REPLACE_PRIMARY_JOB,
    STORE_UPDATE_SALARY_INFO_ERROR,
    STORE_REPLACE_SALARY_INFO,
    STORE_CREATE_PHONE_ERROR,
    STORE_REPLACE_PHONE,
    STORE_RESET_SIGNUP_BACKEND_ERRORS,
    STORE_SIGNUP_ERROR,
    STORE_SIGNUP_SUCCESS,
    STORE_VERIFY_PHONE_ERROR,
    STORE_VERIFY_PHONE_SUCCESS,
    STORE_PHONE_BACK_STEP,
    STORE_LANDING_PAGE_CLICK_PROFILE,
    STORE_VERIFY_INVITE_CARD_CODE_SUCCESS,
    STORE_VERIFY_INVITE_CARD_CODE_ERROR,
    STORE_CLEAR_CARD_INVITE,
    ANALYTICS_LANDING_PAGE_CLICK_PROFILE,
    ANALYTICS_LANDING_PAGE_CLICK_STOREFRONT,
    ANALYTICS_ONBOARDING_ADD_COMPANY,
    ANALYTICS_ONBOARDING_ADD_PHONE_NUMBER,
    ANALYTICS_ONBOARDING_VERIFY_PHONE_NUMBER,
    ANALYTICS_ONBOARDING_ADD_NET_PAY,
    ANALYTICS_SIGNUP_SUCCESS,
    ANALYTICS_CARD_QUALIFY_PAGE_VIEW,
    ANALYTICS_CLICK_CARD_SKIP_BUTTON,
    ANALYTICS_CLICK_CARD_QUALIFY_BUTTON,
    ANALYTICS_ONBOARDING_EMPLOYER_REJECTED,
    ANALYTICS_ONBOARDING_EMPLOYER_REJECTED_CLICK_ADD_NEW_EMPLOYER,
    ANALYTICS_ONBOARDING_EMPLOYER_REJECTED_CLICK_MOVE_TO_MARKETPLACE_SIGNUP,
} from '@perpay-web/fintech/constants/actionTypes';

// salaryInfo \\
export function salaryInfo({
    uuid,
    company,
    selfOnboardingData,
    payCycle,
    estimatedNetPay,
}) {
    return {
        type: BACKEND_UPDATE_SALARY_INFO,
        payload: {
            company:
                company && uuid
                    ? {
                          name: company,
                          uuid,
                      }
                    : undefined,
            selfOnboardingData,
            // We ask for dollars in the UI but the serializer expects cents
            estimatedNetPay: estimatedNetPay
                ? dollarToCents(estimatedNetPay)
                : undefined,
            payCycle,
        },
    };
}

export function salaryInfoError(error) {
    return {
        type: STORE_UPDATE_SALARY_INFO_ERROR,
        payload: error,
    };
}

export function salaryInfoSuccess({
    company,
    eid,
    estimatedNetPay,
    payCycle,
    payCycleDescription,
    uuid,
}) {
    return {
        type: STORE_REPLACE_SALARY_INFO,
        payload: {
            company: { uuid: company.uuid, name: company.name },
            eid,
            // We ask for dollars in the UI but the serializer expects cents
            estimatedNetPay: estimatedNetPay
                ? dollarToCents(estimatedNetPay)
                : undefined,
            payCycle,
            payCycleDescription,
            uuid,
        },
    };
}

// primaryJob \\
export function primaryJob({
    uuid,
    company,
    selfOnboardingData,
    payCycle,
    estimatedNetPay,
}) {
    return {
        type: BACKEND_CREATE_PRIMARY_JOB,
        payload: {
            company: {
                name: company,
                uuid,
            },
            selfOnboardingData,
            // We ask for dollars in the UI but the serializer expects cents
            estimatedNetPay: estimatedNetPay
                ? dollarToCents(estimatedNetPay)
                : undefined,
            payCycle,
        },
    };
}

export function primaryJobError(error) {
    return {
        type: STORE_CREATE_PRIMARY_JOB_ERROR,
        payload: error,
    };
}

export function primaryJobSuccess(jobData) {
    return {
        type: STORE_REPLACE_PRIMARY_JOB,
        payload: jobData,
    };
}

// setPhone \\
export function setPhone(values) {
    return {
        type: BACKEND_CREATE_PHONE,
        payload: values,
    };
}

export function setPhoneError(error) {
    return {
        type: STORE_CREATE_PHONE_ERROR,
        payload: error,
    };
}

export function setPhoneSuccess() {
    return {
        type: STORE_REPLACE_PHONE,
    };
}

// verifyPhone \\
export function verifyPhone(values) {
    return {
        type: BACKEND_VERIFY_PHONE,
        payload: values,
    };
}

export function verifyPhoneError(error) {
    return {
        type: STORE_VERIFY_PHONE_ERROR,
        payload: error,
    };
}

export function verifyPhoneSuccess() {
    return {
        type: STORE_VERIFY_PHONE_SUCCESS,
    };
}

// values = { inviteCodeKey: inviteCodeValue } for card first signup
export function verifyCardInviteCode(values) {
    return {
        type: BACKEND_VERIFY_CARD_INVITE_CODE,
        payload: values,
    };
}
export function verifyCardInviteCodeError(error) {
    return {
        type: STORE_VERIFY_INVITE_CARD_CODE_ERROR,
        payload: error,
    };
}
export function verifyCardInviteCodeSuccess(values) {
    return {
        type: STORE_VERIFY_INVITE_CARD_CODE_SUCCESS,
        payload: values,
    };
}
export function resetBackendSignupErrors() {
    return {
        type: STORE_RESET_SIGNUP_BACKEND_ERRORS,
    };
}

export function signup(values) {
    return {
        type: BACKEND_SIGNUP,
        payload: values,
    };
}

export function signupError(error) {
    return {
        type: STORE_SIGNUP_ERROR,
        payload: error,
    };
}

export function signupSuccess(userData) {
    return {
        type: STORE_SIGNUP_SUCCESS,
        payload: userData,
    };
}

export function phoneBackStep() {
    return {
        type: STORE_PHONE_BACK_STEP,
    };
}

export function clearCardInvite() {
    return {
        type: STORE_CLEAR_CARD_INVITE,
    };
}
export function analyticsLandingClickStorefront() {
    return {
        type: ANALYTICS_LANDING_PAGE_CLICK_STOREFRONT,
    };
}

export function analyticsLandingClickProfile() {
    return {
        type: ANALYTICS_LANDING_PAGE_CLICK_PROFILE,
    };
}

export function landingPageClickProfile() {
    return {
        type: STORE_LANDING_PAGE_CLICK_PROFILE,
    };
}

export function analyticsOnboardingAddCompany(companyName) {
    return {
        type: ANALYTICS_ONBOARDING_ADD_COMPANY,
        payload: { companyName },
    };
}

export function analyticsOnboardingAddPhoneNumber() {
    return {
        type: ANALYTICS_ONBOARDING_ADD_PHONE_NUMBER,
    };
}

export function analyticsOnboardingVerifyPhoneNumber() {
    return {
        type: ANALYTICS_ONBOARDING_VERIFY_PHONE_NUMBER,
    };
}

export function analyticsOnboardingAddNetPay(netPay) {
    return {
        type: ANALYTICS_ONBOARDING_ADD_NET_PAY,
        payload: { netPay },
    };
}

export function analyticsOnboardingEmployerRejected() {
    return {
        type: ANALYTICS_ONBOARDING_EMPLOYER_REJECTED,
    };
}
export function analyticsOnboardingEmployerRejectClickAddNewEmployer() {
    return {
        type: ANALYTICS_ONBOARDING_EMPLOYER_REJECTED_CLICK_ADD_NEW_EMPLOYER,
    };
}
export function analyticsOnboardingEmployerRejectClickMoveToMarketplaceSignup() {
    return {
        type: ANALYTICS_ONBOARDING_EMPLOYER_REJECTED_CLICK_MOVE_TO_MARKETPLACE_SIGNUP,
    };
}
export function analyticsSignupSuccess(userData) {
    return {
        type: ANALYTICS_SIGNUP_SUCCESS,
        payload: userData,
    };
}

export function analyticsOnbardingCardQualifyPageView() {
    return {
        type: ANALYTICS_CARD_QUALIFY_PAGE_VIEW,
    };
}

export function analyticsClickCardSkipButton() {
    return {
        type: ANALYTICS_CLICK_CARD_SKIP_BUTTON,
    };
}

export function analyticsClickCardQualifyButton(timestamp) {
    return {
        type: ANALYTICS_CLICK_CARD_QUALIFY_BUTTON,
        payload: { timestamp },
    };
}
