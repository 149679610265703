import {
    POST_AUTH_REDIRECT,
    ROUTE_TO_JWT_LOCATION,
    ROUTE_TO_LOCATION,
    ROUTE_TO_SHOP,
} from '@perpay-web/fintech/constants/actionTypes';

export function routeToLocation(payload) {
    const isString = typeof payload === 'string';
    const path = isString ? payload : payload.path;
    const props = isString ? {} : payload.props;
    const replace = isString ? false : payload.replace;

    return {
        type: ROUTE_TO_LOCATION,
        payload: {
            path,
            props,
            replace,
        },
    };
}

export function routeToJwtEnabledLocation(url, tokenParamKey) {
    return {
        type: ROUTE_TO_JWT_LOCATION,
        payload: { url, tokenParamKey },
    };
}

export function routeToShop(url) {
    return {
        type: ROUTE_TO_SHOP,
        payload: { url },
    };
}

export function postAuthRedirect() {
    return {
        type: POST_AUTH_REDIRECT,
    };
}
