import { useState } from 'react';

import { useMountAndUnmount } from '@perpay-web/hooks/useMountAndUnmount';
import { useTimedEvent } from '@perpay-web/hooks/useTimedEvent';
import { getClassName } from '@perpay-web/utils/domUtils';

import styles from './LoaderCircle.scss';

const DEFAULT_DELAY = 0;

export const DARK_MODE = 'dark';
const LIGHT_MODE = 'light';

const LoaderCircle = ({ delay = DEFAULT_DELAY, mode = LIGHT_MODE }) => {
    const [hidden, setHidden] = useState(delay > 0);
    const { startTimer, clearTimer } = useTimedEvent(() => {
        setHidden(false);
    }, delay);

    useMountAndUnmount(() => {
        startTimer();
        return () => clearTimer();
    });

    return hidden ? null : (
        <div className={styles['loader-container']}>
            <div
                className={getClassName(
                    styles.loader,
                    mode === DARK_MODE ? styles['loader--dark'] : '',
                )}
            />
        </div>
    );
};

export default LoaderCircle;
