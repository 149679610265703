import { LEGACY_PERPAY_PLUS } from '@perpay-web/fintech/constants/featureTypes';
import { FEATURE_ENROLLMENTS } from '@perpay-web/fintech/constants/tableNames';

const getLegacyPerpayPlusEnrollments = (state) =>
    Object.keys(state.entities[FEATURE_ENROLLMENTS])
        .filter(
            (uuid) =>
                state.entities[FEATURE_ENROLLMENTS][uuid].feature ===
                LEGACY_PERPAY_PLUS,
        )
        .map((uuid) => state.entities[FEATURE_ENROLLMENTS][uuid]);

// Get the most recent job, via the last element of the job entities map.
const getPrimaryJob = (state) =>
    Object.values(state.entities.jobs).slice(-1)[0] || {};

export const getJobDetails = (state) => {
    const primaryJob = getPrimaryJob(state);
    const companyName = primaryJob.company ? primaryJob.company.name : '';
    return {
        uuid: primaryJob.uuid,
        companyUuid: primaryJob.company ? primaryJob.company.uuid : '',
        companyName,
        payCycleDescription: (primaryJob.payCycleDescription || '').split(
            ',',
        )[0],
        payCycle: primaryJob.payCycle || '',
        supportsDecimalDirectDeposits: primaryJob.company
            ? !primaryJob.company.roundToDollar
            : true,
        // NOTE: We keep the "full paycheck" name here to avoid having to migrate backend data
        perpaySplitEligible: primaryJob.payrollProviderType === 'full_paycheck',
        isGigEconomyCompany: primaryJob.isGigEconomyCompany,
    };
};

export const getIsPerpaySplitEligible = (state) =>
    getJobDetails(state).perpaySplitEligible;

// TODO: Remove if unused
// eslint-disable-next-line import/no-unused-modules
export const getIsLoading = (state) => !Object.values(state.entities.jobs)[0];

export const getCompanyUnverified = (state) => {
    const primaryJob = getPrimaryJob(state);
    return primaryJob.company ? !primaryJob.company.verified : false;
};

const payCycleToLegacyPerpayPlusPays = {
    monthly: 6,
    bi_weekly: 12,
    semi_monthly: 12,
    weekly: 24,
};

const payCycleToPerpayPlusPays = {
    monthly: 5,
    bi_weekly: 10,
    semi_monthly: 10,
    weekly: 20,
};

export const getPerpayPlusPays = (state) => {
    const enrollments = getLegacyPerpayPlusEnrollments(state);
    const legacyPerpayPlus = Boolean(enrollments.length);
    const { payCycle } = getJobDetails(state);
    if (legacyPerpayPlus) {
        return payCycleToLegacyPerpayPlusPays[payCycle];
    }
    return payCycleToPerpayPlusPays[payCycle];
};
