import { exhaustMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { dispatchObservable } from '@perpay-web/observable/dispatchObservable';
import { reduxStepsSetStep } from '@perpay-web/hooks/useReduxSteps';
import {
    BACKEND_CREATE_WITHDRAWAL_REQUEST_UI,
    BACKEND_CREATE_WITHDRAWAL_SUCCESS,
    BACKEND_CREATE_WITHDRAWAL_ERROR,
} from '@perpay-web/fintech/constants/actionTypes';
import { createWithdrawalRequest } from '@perpay-web/fintech/actions/ui/withdrawal';
import {
    STEPS,
    WITHDRAWAL_SUCCESS,
} from '@perpay-web/fintech/constants/steps/withdrawalSteps';

export const createWithdrawalRequestUI = (action$, state$) =>
    action$.pipe(
        ofType(BACKEND_CREATE_WITHDRAWAL_REQUEST_UI),
        exhaustMap((action) =>
            dispatchObservable({
                action$,
                state$,
                initialDispatch: () => [
                    createWithdrawalRequest(action.payload),
                ],
                waitFor: [BACKEND_CREATE_WITHDRAWAL_SUCCESS],
                waitForDispatch: () => [
                    reduxStepsSetStep(STEPS, WITHDRAWAL_SUCCESS),
                ],
                // The UI consumes the errors that are stored by the createBank epic.
                errors: [BACKEND_CREATE_WITHDRAWAL_ERROR],
                errorDispatch: () => [],
                // Timeouts are handled within the bank entity epic
            }),
        ),
    );
