import {
    BACKEND_CREATE_WITHDRAWAL_REQUEST,
    BACKEND_CREATE_WITHDRAWAL_REQUEST_UI,
    BACKEND_CREATE_WITHDRAWAL_SUCCESS,
    BACKEND_CREATE_WITHDRAWAL_ERROR,
    BACKEND_FETCH_WITHDRAWAL_BALANCE,
    STORE_REPLACE_WITHDRAWAL_BALANCE,
    STORE_REPLACE_WITHDRAWAL_BALANCE_ERROR,
} from '@perpay-web/fintech/constants/actionTypes';

export function createWithdrawalRequest(values) {
    return {
        type: BACKEND_CREATE_WITHDRAWAL_REQUEST,
        payload: values,
    };
}

export function createWithdrawalRequestUI(values) {
    return {
        type: BACKEND_CREATE_WITHDRAWAL_REQUEST_UI,
        payload: values,
    };
}

// eslint-disable-next-line import/no-unused-modules
export function createWithdrawalRequestSuccess() {
    return {
        type: BACKEND_CREATE_WITHDRAWAL_SUCCESS,
    };
}

// eslint-disable-next-line import/no-unused-modules
export function createWithdrawalRequestErrors(errors) {
    return {
        type: BACKEND_CREATE_WITHDRAWAL_ERROR,
        payload: errors,
    };
}

export function fetchWithdrawalBalance() {
    return {
        type: BACKEND_FETCH_WITHDRAWAL_BALANCE,
    };
}

export function replaceWithdrawalBalance(withdrawalBalance) {
    return {
        type: STORE_REPLACE_WITHDRAWAL_BALANCE,
        payload: withdrawalBalance,
    };
}

export function withdrawalBalanceError(error) {
    return {
        type: STORE_REPLACE_WITHDRAWAL_BALANCE_ERROR,
        payload: error,
    };
}
