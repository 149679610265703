import { createBrowserRouter, RouterProvider } from 'react-router-dom';

/**
 * @type {import('@remix-run/router').Router} Router
 */
let router;

/**
 *
 * @param {import('react-router-dom').RouteObject[]} routes
 * @param {(router: import('@remix-run/router').Router) => void} onInitialize
 * @returns
 */
export const getOrCreateRouter = (routes = [], onInitialize) => {
    if (!router) {
        router = createBrowserRouter(routes, {
            // Allow easier migration with future feature flags!
            // TODO: Remove this when migrating to React Router V7
            future: {
                v7_fetcherPersist: true,
                v7_normalizeFormMethod: true,
                v7_partialHydration: true,
                v7_relativeSplatPath: true,
                v7_skipActionErrorRevalidation: true,
            },
        });
        if (onInitialize) {
            onInitialize(router);
        }
    }
    return router;
};

export const AppRouterProvider = () => <RouterProvider router={router} />;

/**
 * @param {import('react-router-dom').To} to
 */
export const push = (to) => getOrCreateRouter().navigate(to);

/**
 * @param {import('react-router-dom').To} to
 */
export const replace = (to) =>
    getOrCreateRouter().navigate(to, { replace: true });

export const getRouterState = () => getOrCreateRouter().state;
