import StepsProviders from '@perpay-web/fintech/components/base/StepsProviders/StepsProviders';
import ComponentStackProviders from '@perpay-web/fintech/components/base/ComponentStackProviders/ComponentStackProviders';
import ModalProviders from '@perpay-web/fintech/components/base/ModalProviders/ModalProviders';
import { PartnerHostedShowDetailedFormProvider } from '@perpay-web/fintech/hooks/usePartnerHostedShowDetailedFormContext';
import { createCombinedProvider } from '@perpay-web/utils/componentUtils';

const FintechGlobalProviders = createCombinedProvider(
    ComponentStackProviders,
    StepsProviders,
    ModalProviders,
    PartnerHostedShowDetailedFormProvider,
);

export default FintechGlobalProviders;
