import { connect } from 'react-redux';
import {
    analyticsPerpayCreditCardRewardsNotificationViewed,
    analyticsPerpayCreditCardRewardsNotificationClickCta,
    analyticsPerpayCreditCardRewardsNotificationClose,
} from '@perpay-web/fintech/actions/analytics/perpayCreditCardRewardsNotification';

import { fetchUserInfo } from '@perpay-web/fintech/actions/entities/userInfo';
import {
    getFirstName,
    getIsLoading,
} from '@perpay-web/fintech/selectors/entities/userInfo';
import CardRewardsNotification from '@perpay-web/fintech/components/composite/CardRewardsNotification/CardRewardsNotification';

const mapStateToProps = (state, ownProps) => {
    const firstName = getFirstName(state);
    const isLoading = getIsLoading(state);

    return {
        isLoading,
        firstName,
        cardRewardsAmount: ownProps.cardRewardsAmount,
    };
};
const mapDispatchToProps = (dispatch) => ({
    onMount: () => {
        dispatch(fetchUserInfo());
        dispatch(analyticsPerpayCreditCardRewardsNotificationViewed());
    },
    analyticsOnClose: () =>
        dispatch(analyticsPerpayCreditCardRewardsNotificationClose()),
    analyticsOnClickCta: () =>
        dispatch(analyticsPerpayCreditCardRewardsNotificationClickCta()),
});

// Legacy usage of Redux connect API. New code should not use this pattern
// eslint-disable-next-line @perpay-web/no-redux-connect
const CardRewardsNotificationContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(CardRewardsNotification);

export default CardRewardsNotificationContainer;
