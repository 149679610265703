import { createDataModule } from '@perpay-web/data-module/createDataModule';
import {
    AWAITING_PAYMENT,
    STOP_PAYMENT,
    STOP_PAYMENT_CANCELED_WITH_DEDUCTION,
    DELINQUENT,
    LATE,
    GOOD,
} from '@perpay-web/fintech/constants/borrowerStatuses';
import {
    fetchUserStatusForDataModule,
    fetchUserStatusSuccess,
    fetchUserStatusError,
    fetchUserStatusReset,
} from '@perpay-web/fintech/actions/entities/userStatus';

const FetchUserStatusDataModule = createDataModule({
    dataRequest: fetchUserStatusForDataModule,
    dataSuccess: fetchUserStatusSuccess,
    dataError: fetchUserStatusError,
    dataReset: fetchUserStatusReset,
});

const getRoot = (state) => state.dataModules.fetchUserStatus;

const initialValue = {};

export const fetchUserStatusDataModule = FetchUserStatusDataModule({
    getRoot,
    initialValue,
});

export const getIsFirstTimeBorrower = (state) => {
    const { metadata } = fetchUserStatusDataModule.getData(state);
    if (metadata) {
        return metadata.firstOrder || false;
    }

    return false;
};

export const getUserIsFirstTimeBorrowerInAwaitingPayment = (state) =>
    fetchUserStatusDataModule.getData(state).status === AWAITING_PAYMENT &&
    getIsFirstTimeBorrower(state);

export const getUserStatusToInstructionsType = (state) => {
    const userStatus = fetchUserStatusDataModule.getData(state);
    switch (userStatus.status) {
        case STOP_PAYMENT:
        case STOP_PAYMENT_CANCELED_WITH_DEDUCTION:
            return 'stop';
        case DELINQUENT:
        case LATE:
            return 'late';
        default:
        case GOOD:
            return 'start';
    }
};
