import { lazy } from 'react';
import { Route, Navigate } from 'react-router-dom';

import { compose } from '@perpay-web/utils/functionalUtils';
import { withRedirectIfUnauthenticated } from '@perpay-web/components/higher-order/WithRedirectIfUnauthenticated/WithRedirectIfUnauthenticated';
import { withRedirectIfIncomplete } from '@perpay-web/components/higher-order/WithRedirectIfIncomplete/WithRedirectIfIncomplete';
import { withBottomNav } from '@perpay-web/components/higher-order/WithBottomNav/WithBottomNav';
import { withPerpayTitle } from '@perpay-web/components/higher-order/WithPerpayTitle/WithPerpayTitle';
import { withLoader } from '@perpay-web/components/higher-order/WithLoader/WithLoader';
import { paths } from '@perpay-web/fintech/props/appPaths';
import CardOnboardingSwitcherContainer from '@perpay-web/fintech/components/containers/CardOnboardingSwitcherContainer';
import { importCardActivationContainer } from '@perpay-web/fintech/utils/imports/importCardActivationContainer';
import { importEditEmployment } from '@perpay-web/fintech/utils/imports/importEditEmployment';
import { importBankAccountVerifySelectContainer } from '@perpay-web/fintech/utils/imports/importBankAccountVerifySelectContainer';
import { importWithdrawalContainer } from '@perpay-web/fintech/utils/imports/importWithdrawalContainer';
import { importCreditScoresContainer } from '@perpay-web/fintech/utils/imports/importCreditScoresContainer';
import { importCreditScoreDetailsContainer } from '@perpay-web/fintech/utils/imports/importCreditScoreDetailsContainer';
import { importDashboardContainer } from '@perpay-web/fintech/utils/imports/importDashboardContainer';
import { importPaymentPortalContainer } from '@perpay-web/fintech/utils/imports/importPaymentPortalContainer';
import { importRecurringPaymentPortalContainer } from '@perpay-web/fintech/utils/imports/importRecurringPaymentPortalContainer';
import { importOneTimePaymentPortalContainer } from '@perpay-web/fintech/utils/imports/importOneTimePaymentPortalContainer';
import { importStandaloneUploadPaystubFlow } from '@perpay-web/fintech/utils/imports/importStandaloneUploadPaystubFlow';
import { importExternalCardAdd } from '@perpay-web/fintech/utils/imports/importExternalCardAdd';
import { importBankAccountVerificationContainer } from '@perpay-web/fintech/utils/imports/importBankAccountVerificationContainer';
import { importBankAccountAddContainer } from '@perpay-web/fintech/utils/imports/importBankAccountAddContainer';
import { importIdentityVerificationContainer } from '@perpay-web/fintech/utils/imports/importIdentityVerificationContainer';
import { importReferralsContainer } from '@perpay-web/fintech/utils/imports/importReferralsContainer';
import { importPaymentSetupContainer } from '@perpay-web/fintech/utils/imports/importPaymentSetupContainer';
import { importPayrollInstructions } from '@perpay-web/fintech/utils/imports/importPayrollInstructions';
import { importCheckoutContainer } from '@perpay-web/fintech/utils/imports/importCheckoutContainer';
import { importProfile } from '@perpay-web/fintech/utils/imports/importProfile';
import { importOrders } from '@perpay-web/fintech/utils/imports/importOrders';
import { importCardDetailsContainer } from '@perpay-web/fintech/utils/imports/importCardDetailsContainer';
import { importCardCreateAutoPayContainer } from '@perpay-web/fintech/utils/imports/importCardCreateAutoPayContainer';
import { importCardStatementsScreenContainer } from '@perpay-web/fintech/utils/imports/importCardStatementsContainer';
import { importCardSettingsContainer } from '@perpay-web/fintech/utils/imports/importCardSettingsContainer';
import { importCardLearnContainer } from '@perpay-web/fintech/utils/imports/importCardLearnContainer';
import { importDirectDepositDetailsContainer } from '@perpay-web/fintech/utils/imports/importDirectDepositDetailsContainer';
import { importUpdateDirectDepositContainer } from '@perpay-web/fintech/utils/imports/importUpdateDirectDepositContainer';
import { importRewardsAndCreditsContainer } from '@perpay-web/fintech/utils/imports/importRewardsAndCreditsContainer';
import { importRewardsAndCreditsDetailsContainer } from '@perpay-web/fintech/utils/imports/importRewardsAndCreditsDetailsContainer';
import { importRewardHistoryContainer } from '@perpay-web/fintech/utils/imports/importRewardsHistoryContainer';
import { importMarketplaceDetailsContainer } from '@perpay-web/fintech/utils/imports/importMarketplaceDetailsContainer';
import { importMarketplaceLearnContainer } from '@perpay-web/fintech/utils/imports/importMarketplaceLearnContainer';
import { importCardActivityScreenContainer } from '@perpay-web/fintech/utils/imports/importCardActivityScreenContainer';
import { importPerpayPlusContainer } from '@perpay-web/fintech/utils/imports/importPerpayPlusContainer';
import { importMarketplaceActivityContainer } from '@perpay-web/fintech/utils/imports/importMarketplaceActivityContainer';
import { importDirectDepositActivityContainer } from '@perpay-web/fintech/utils/imports/importDirectDepositActivityContainer';
import { importPartnerOnboardingContainer } from '@perpay-web/fintech/utils/imports/importPartnerOnboardingContainer';
import { withBlankHeader } from '@perpay-web/fintech/components/higher-order/WithBlankHeader/WithBlankHeader';

const EmptyBottomNav = () => null;
const withAuthenticatedRouteBehavior = compose(
    withRedirectIfIncomplete,
    withRedirectIfUnauthenticated,
);

const CheckoutRoute = compose(
    withPerpayTitle('Checkout'),
    withBottomNav(EmptyBottomNav),
    withAuthenticatedRouteBehavior,
)(withLoader(lazy(importCheckoutContainer)));

const PayrollInstructionsRoute = compose(
    withBlankHeader,
    withBottomNav(EmptyBottomNav),
    withAuthenticatedRouteBehavior,
)(withLoader(lazy(importPayrollInstructions)));

const PaymentSetupRoute = compose(
    withPerpayTitle('Payment Setup'),
    withBottomNav(EmptyBottomNav),
    withAuthenticatedRouteBehavior,
)(withLoader(lazy(importPaymentSetupContainer)));

const ReferralsRoute = compose(
    withPerpayTitle('Referrals'),
    withBottomNav(EmptyBottomNav),
    withAuthenticatedRouteBehavior,
)(withLoader(lazy(importReferralsContainer)));

const IdentityVerificationRoute = compose(
    withPerpayTitle('Verify Identity'),
    withBottomNav(EmptyBottomNav),
    withAuthenticatedRouteBehavior,
)(withLoader(lazy(importIdentityVerificationContainer)));

const BankAccountAddRoute = compose(
    withPerpayTitle('Add Your Bank'),
    withBottomNav(EmptyBottomNav),
    withAuthenticatedRouteBehavior,
)(withLoader(lazy(importBankAccountAddContainer)));

const BankAccountVerificationRoute = compose(
    withPerpayTitle('Verify Bank Account'),
    withBottomNav(EmptyBottomNav),
    withAuthenticatedRouteBehavior,
)(withLoader(lazy(importBankAccountVerificationContainer)));

const ExternalCardAddWithLoader = compose(
    withPerpayTitle('Add Your Card'),
    withBottomNav(EmptyBottomNav),
    withAuthenticatedRouteBehavior,
)(withLoader(lazy(importExternalCardAdd)));

const StandaloneUploadPaystubFlowWithLoader = compose(
    withBottomNav(EmptyBottomNav),
    withAuthenticatedRouteBehavior,
)(withLoader(lazy(importStandaloneUploadPaystubFlow)));

const OneTimePaymentPortalRoute = compose(
    withPerpayTitle('Payment Portal'),
    withBottomNav(EmptyBottomNav),
    withAuthenticatedRouteBehavior,
)(withLoader(lazy(importOneTimePaymentPortalContainer)));

const RecurringPaymentPortalRoute = compose(
    withPerpayTitle('Payment Portal'),
    withBottomNav(EmptyBottomNav),
    withAuthenticatedRouteBehavior,
)(withLoader(lazy(importRecurringPaymentPortalContainer)));

const PaymentPortalRoute = compose(
    withPerpayTitle('Payment Portal'),
    withBottomNav(EmptyBottomNav),
    withAuthenticatedRouteBehavior,
)(withLoader(lazy(importPaymentPortalContainer)));

const WithdrawalRoute = compose(
    withBottomNav(EmptyBottomNav),
    withAuthenticatedRouteBehavior,
)(withLoader(lazy(importWithdrawalContainer)));

const BankAccountVerifySelectRoute = compose(
    withBottomNav(EmptyBottomNav),
    withAuthenticatedRouteBehavior,
)(withLoader(lazy(importBankAccountVerifySelectContainer)));

const EditEmploymentWithLoader = compose(
    withBottomNav(EmptyBottomNav),
    withAuthenticatedRouteBehavior,
)(withLoader(lazy(importEditEmployment)));

const CardDetailsRoute = compose(
    withPerpayTitle('Card Details'),
    withAuthenticatedRouteBehavior,
)(withLoader(lazy(importCardDetailsContainer)));

const CardCreateAutoPayRoute = compose(
    withBottomNav(EmptyBottomNav),
    withAuthenticatedRouteBehavior,
)(withLoader(lazy(importCardCreateAutoPayContainer)));

const CardStatementsScreenRoute = compose(
    withPerpayTitle('Statements'),
    withBottomNav(EmptyBottomNav),
    withAuthenticatedRouteBehavior,
)(withLoader(lazy(importCardStatementsScreenContainer)));

const CardSettingsRoute = compose(
    withPerpayTitle('Card Settings'),
    withBottomNav(EmptyBottomNav),
    withAuthenticatedRouteBehavior,
)(withLoader(lazy(importCardSettingsContainer)));

const DirectDepositDetailsRoute = compose(
    withPerpayTitle('Direct Deposit Details'),
    withBottomNav(EmptyBottomNav),
    withAuthenticatedRouteBehavior,
)(withLoader(lazy(importDirectDepositDetailsContainer)));

const UpdateDirectDepositRoute = compose(
    withPerpayTitle('Update Your Payroll Direct Deposit'),
    withBottomNav(EmptyBottomNav),
    withAuthenticatedRouteBehavior,
)(withLoader(lazy(importUpdateDirectDepositContainer)));

const RewardsAndCreditsRoute = compose(
    withPerpayTitle('Rewards & Credits'),
    withBottomNav(EmptyBottomNav),
    withAuthenticatedRouteBehavior,
)(withLoader(lazy(importRewardsAndCreditsContainer)));

const RewardsAndCreditsDetailsRoute = compose(
    withPerpayTitle('Rewards & Credits'),
    withBottomNav(EmptyBottomNav),
    withAuthenticatedRouteBehavior,
)(withLoader(lazy(importRewardsAndCreditsDetailsContainer)));

const RewardHistoryRoute = compose(
    withPerpayTitle('Reward History'),
    withBottomNav(EmptyBottomNav),
    withAuthenticatedRouteBehavior,
)(withLoader(lazy(importRewardHistoryContainer)));

const PerpayPlusRoute = compose(
    withPerpayTitle('Perpay Plus'),
    withBottomNav(EmptyBottomNav),
    withAuthenticatedRouteBehavior,
)(withLoader(lazy(importPerpayPlusContainer)));

const CardActivationContainer = compose(
    withBottomNav(EmptyBottomNav),
    withAuthenticatedRouteBehavior,
)(withLoader(lazy(importCardActivationContainer)));

const MarketplaceActivityRoute = compose(
    withPerpayTitle('Marketplace Activity'),
    withBottomNav(EmptyBottomNav),
    withAuthenticatedRouteBehavior,
)(withLoader(lazy(importMarketplaceActivityContainer)));

const DirectDepositActivityRoute = compose(
    withPerpayTitle('Direct Deposit Activity'),
    withBottomNav(EmptyBottomNav),
    withAuthenticatedRouteBehavior,
)(withLoader(lazy(importDirectDepositActivityContainer)));

const DashboardRoute = compose(
    withPerpayTitle('Dashboard'),
    withAuthenticatedRouteBehavior,
)(withLoader(lazy(importDashboardContainer)));

const OrdersWithLoader = compose(
    withPerpayTitle('Orders'),
    withAuthenticatedRouteBehavior,
)(withLoader(lazy(importOrders)));

const ProfileWithLoader = compose(
    withPerpayTitle('Profile'),
    withAuthenticatedRouteBehavior,
)(withLoader(lazy(importProfile)));

const CardActivityScreenContainer = compose(
    withPerpayTitle('Card Activity'),
    withBottomNav(EmptyBottomNav),
    withAuthenticatedRouteBehavior,
)(withLoader(lazy(importCardActivityScreenContainer)));

const CardOnboardingContainer = compose(
    withPerpayTitle('Perpay Card'),
    withBottomNav(EmptyBottomNav),
    withAuthenticatedRouteBehavior,
)(withLoader(CardOnboardingSwitcherContainer));

const CardLearnContainer = compose(
    withPerpayTitle('Perpay Card'),
    withAuthenticatedRouteBehavior,
)(withLoader(lazy(importCardLearnContainer)));

const MarketplaceDetailsRoute = compose(
    withPerpayTitle('Marketplace Details'),
    withAuthenticatedRouteBehavior,
)(withLoader(lazy(importMarketplaceDetailsContainer)));

const MarketplaceLearnRoute = compose(
    withPerpayTitle('Marketplace'),
    withAuthenticatedRouteBehavior,
)(withLoader(lazy(importMarketplaceLearnContainer)));

const CreditScoresRoute = compose(
    withPerpayTitle('Credit Monitoring'),
    withAuthenticatedRouteBehavior,
)(withLoader(lazy(importCreditScoresContainer)));

const CreditScoreDetailsRoute = compose(
    withPerpayTitle('Score History'),
    withAuthenticatedRouteBehavior,
)(withLoader(lazy(importCreditScoreDetailsContainer)));

const PartnerOnboardingApplicationRoute = compose(
    withPerpayTitle('Partner Onboarding'),
    withBottomNav(EmptyBottomNav),
    withAuthenticatedRouteBehavior,
)(withLoader(lazy(importPartnerOnboardingContainer)));

export const renderAuthenticatedRoutes = () => [
    <Route
        path={paths.bankVerifySelect.path}
        Component={BankAccountVerifySelectRoute}
    />,
    <Route path={paths.withdrawal.path} Component={WithdrawalRoute} />,
    <Route path={paths.dashboard.path} Component={DashboardRoute} />,
    <Route
        path={paths.editEmployment.path}
        Component={EditEmploymentWithLoader}
    />,
    <Route path={`${paths.profile.path}/*`} Component={ProfileWithLoader} />,
    <Route path={paths.orders.path} Component={OrdersWithLoader} />,
    <Route path={`${paths.checkout.path}:uuid`} Component={CheckoutRoute} />,
    <Route
        path={paths.paymentInstructions.path}
        Component={PayrollInstructionsRoute}
    />,
    <Route path={paths.paymentPortal.path} Component={PaymentPortalRoute} />,
    <Route
        path={paths.recurringPayments.path}
        Component={RecurringPaymentPortalRoute}
    />,
    <Route
        path={paths.oneTimePayments.path}
        Component={OneTimePaymentPortalRoute}
    />,
    <Route path={paths.paymentSetup.path} Component={PaymentSetupRoute} />,
    <Route path={paths.referrals.path} Component={ReferralsRoute} />,
    <Route
        path={paths.identityVerification.path}
        Component={IdentityVerificationRoute}
    />,
    <Route path={paths.bankCreate.path} Component={BankAccountAddRoute} />,
    <Route
        path={`${paths.bankVerify.path}:uuid`}
        Component={BankAccountVerificationRoute}
    />,
    <Route
        path={paths.externalCardCreate.path}
        Component={ExternalCardAddWithLoader}
    />,
    <Route
        path={paths.uploadPaystub.path}
        Component={StandaloneUploadPaystubFlowWithLoader}
    />,
    <Route path={paths.creditMonitoring.path} Component={CreditScoresRoute} />,
    <Route
        path={paths.creditMonitoringDetails.path}
        Component={CreditScoreDetailsRoute}
    />,
    <Route path={paths.perpayPlus.path} Component={PerpayPlusRoute} />,
    <Route
        path={paths.cardActivate.path}
        Component={CardActivationContainer}
    />,
    <Route
        path={paths.cardOnboarding.path}
        Component={CardOnboardingContainer}
    />,
    <Route path={paths.cardDetails.path} Component={CardDetailsRoute} />,
    <Route path={paths.cardLearn.path} Component={CardLearnContainer} />,
    <Route
        path={paths.cardActivity.path}
        Component={CardActivityScreenContainer}
    />,
    <Route path={paths.cardPayment.path} Component={CardCreateAutoPayRoute} />,
    <Route
        path={paths.cardStatements.path}
        Component={CardStatementsScreenRoute}
    />,
    <Route path={paths.cardSettings.path} Component={CardSettingsRoute} />,
    <Route
        path={paths.directDeposit.path}
        Component={DirectDepositDetailsRoute}
    />,

    <Route
        path={paths.updateDirectDeposit.path}
        Component={UpdateDirectDepositRoute}
    />,
    <Route
        path={paths.updateDirectDepositCreditLimit.path}
        Component={UpdateDirectDepositRoute}
    />,
    <Route
        path={paths.rewardsAndCredits.path}
        Component={RewardsAndCreditsRoute}
    />,
    <Route
        path={paths.marketplaceDetails.path}
        Component={MarketplaceDetailsRoute}
    />,
    <Route
        exact
        path={paths.marketplaceLearn.path}
        Component={MarketplaceLearnRoute}
    />,
    <Route
        exact
        path={paths.rewardsAndCreditsDetails.path}
        Component={RewardsAndCreditsDetailsRoute}
    />,
    <Route path={paths.rewardHistory.path} Component={RewardHistoryRoute} />,
    <Route
        path={paths.marketplaceActivity.path}
        Component={MarketplaceActivityRoute}
    />,
    <Route
        path={paths.directDepositActivity.path}
        Component={DirectDepositActivityRoute}
    />,
    <Route
        path={paths.creditReporting.path}
        element={<Navigate to={paths.perpayPlus.path} />}
    />,
    <Route
        path={paths.bankVerify.path}
        element={<Navigate to={paths.dashboard.path} />}
    />,
    <Route
        path={paths.partnerOnboardingApplication.path}
        Component={PartnerOnboardingApplicationRoute}
    />,
];
