/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useRef, useState } from 'react';

import { useMountAndUnmount } from '@perpay-web/hooks/useMountAndUnmount';

/**
 * This hook is used to trigger timed events
 *
 * triggeredCallback is called `timerDurationMs` after the
 * returned `startTimer` function is called.
 *
 *
 * Example Usage:
 * ```
 * const MyComponent() => {
 *   const onTimerFired = () => { console.log('timed out') };
 *   const { startTimer, clearTimer } = useTimedEvent(onTimerFired, 5000);
 *   return (
 *      <div>
 *          <button onClick={startTimer}>Start</p>
 *          <button onClick={clearTimer}>Clear</p>
 *      </div>
 *   );
 * };
 * ```
 *
 * https://reactjs.org/docs/hooks-effect.html
 */
export function useTimedEvent(triggeredCallback, timerDurationMs, deps = []) {
    const timerId = useRef();
    const [isTimerActive, setIsTimerActive] = useState(false);

    const clearTimer = useCallback(() => {
        if (timerId.current) {
            window.clearTimeout(timerId.current);
            setIsTimerActive(false);
        }
    }, []);

    const startTimer = useCallback(() => {
        timerId.current = window.setTimeout(() => {
            triggeredCallback();
            setIsTimerActive(false);
        }, timerDurationMs);
        setIsTimerActive(true);
    }, [triggeredCallback, timerDurationMs, ...deps]);

    useMountAndUnmount(() => () => clearTimer());

    return { startTimer, clearTimer, isTimerActive };
}
