import { wrapRetry } from '@perpay-web/utils/importUtils';

export const importCardInvite = () =>
    wrapRetry(
        () =>
            import(
                /* webpackChunkName: "CardInvite" */
                '@perpay-web/fintech/components/containers/CardInviteContainer'
            ),
    );
