import { STORE_REPLACE_ACCOUNT_DEACTIVATION_ELIGIBILITY } from '@perpay-web/fintech/constants/actionTypes';

const initialState = {
    eligible: false,
};

const accountDeactivationEligibilityReducer = (
    state = initialState,
    action,
) => {
    const { type, payload } = action;

    switch (type) {
        case STORE_REPLACE_ACCOUNT_DEACTIVATION_ELIGIBILITY:
            return { ...state, eligible: payload };
        default:
            return state;
    }
};

export default accountDeactivationEligibilityReducer;
