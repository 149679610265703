export const NEW = 'new';
export const APPROVED = 'approved';
export const REJECTED = 'rejected';
export const IN_ERROR = 'in_error';
export const CLARITY_FROZEN = 'clarity_frozen';
export const AWAITING_DEDUCTION_SETUP = 'awaiting_deduction_setup';
export const COMPLETE = 'complete';
export const UPLOAD_DOCUMENTS = 'upload_documents';
export const IN_REVIEW = 'in_review';
export const PREFERENCES = 'preferences';
