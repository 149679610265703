import { dispatchObservable } from '@perpay-web/observable/dispatchObservable';
import { ofType } from 'redux-observable';
import { exhaustMap } from 'rxjs/operators';
import { fetchFeatures } from '@perpay-web/fintech/actions/entities/features';
import { fetchUserStatus } from '@perpay-web/fintech/actions/entities/userStatus';
import { routeToLocation } from '@perpay-web/fintech/actions/router';
import {
    fetchCardActivation as fetchCardActivationAction,
    fetchCardActivationSuccess,
    fetchCardActivationError,
} from '@perpay-web/fintech/actions/ui/cardActivation';
import {
    STORE_FETCH_FEATURES_ERROR,
    STORE_REPLACE_FEATURES,
    STORE_REPLACE_USER_STATUS,
    STORE_REPLACE_USER_STATUS_ERROR,
} from '@perpay-web/fintech/constants/actionTypes';
import { CARD_ACTIVATE } from '@perpay-web/fintech/constants/featureDetails';
import { paths } from '@perpay-web/fintech/props/appPaths';
import { getCardFeatureDetails } from '@perpay-web/fintech/selectors/entities/features';

export const fetchCardActivation = (action$, state$) =>
    action$.pipe(
        ofType(fetchCardActivationAction().type),
        exhaustMap(() =>
            dispatchObservable({
                action$,
                state$,
                initialDispatch: () => [fetchUserStatus(), fetchFeatures()],
                waitFor: [STORE_REPLACE_USER_STATUS, STORE_REPLACE_FEATURES],
                waitForDispatch: (state) => {
                    const cardFeatureDetails = getCardFeatureDetails(state);

                    const maybeRedirectAction = !cardFeatureDetails.includes(
                        CARD_ACTIVATE,
                    )
                        ? [
                              routeToLocation({
                                  path: paths.dashboard.path,
                                  replace: true,
                              }),
                          ]
                        : [];

                    return [
                        ...maybeRedirectAction,
                        fetchCardActivationSuccess(),
                    ];
                },
                errors: [
                    STORE_FETCH_FEATURES_ERROR,
                    STORE_REPLACE_USER_STATUS_ERROR,
                ],
                errorDispatch: () => [
                    routeToLocation({
                        path: paths.dashboard.path,
                        replace: true,
                    }),
                    fetchCardActivationError(),
                ],
            }),
        ),
    );
