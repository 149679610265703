import { wrapRetry } from '@perpay-web/utils/importUtils';

// eslint-disable-next-line import/no-unused-modules
export const importMarketplaceLearnContainer = () =>
    wrapRetry(
        () =>
            import(
                /* webpackChunkName: "MarketplaceLearnContainer" */
                '@perpay-web/fintech/components/containers/MarketplaceLearnContainer'
            ),
    );
