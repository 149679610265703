import { replace } from '@perpay-web/services/router';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

const MagentoRedirect = ({ storefrontPath, magentoRewrite }) => {
    const params = useParams();

    useEffect(() => {
        const targetRoute = magentoRewrite
            ? magentoRewrite(params)
            : Object.entries(params).reduce(
                  (route, [key, value]) => route.replace(`:${key}`, value),
                  storefrontPath,
              );
        replace({ pathname: targetRoute, search: window.location.search });
    }, [storefrontPath, params, magentoRewrite]);
};

export default MagentoRedirect;
