import {
    BACKEND_CARD_UPDATE_USER_INFO,
    BACKEND_CARD_UPDATE_USER_INFO_ERROR,
} from '@perpay-web/fintech/constants/actionTypes';

export function cardUpdateUserInfo(address) {
    return {
        type: BACKEND_CARD_UPDATE_USER_INFO,
        payload: {
            streetAddr: `${address.streetAddr} ${address.addressLine2}`,
            city: address.city,
            state: address.state,
            zipcode: address.zipcode,
        },
    };
}

export function cardUpdateUserInfoError(error) {
    return {
        type: BACKEND_CARD_UPDATE_USER_INFO_ERROR,
        payload: error,
    };
}
