import {
    ANALYTICS_CREATE_APPLICATION_SUBMITTED,
    ANALYTICS_CREATE_PURCHASE_SUBMITTED_FROM_MAGENTO,
    ANALYTICS_SIGNUP_SUCCESS,
} from '@perpay-web/fintech/constants/actionTypes';
import {
    completeRegistration,
    initiatePurchase,
    purchase,
} from '@perpay-web/fintech/beacon/eventDefinitions/Branch';
import { getOrderAmountWithoutCredit } from '@perpay-web/fintech/utils/checkoutUtils';

const branchEventsMap = {
    [ANALYTICS_CREATE_APPLICATION_SUBMITTED]: purchase((action, prevState) => ({
        id: action.payload.order_uuid,
        amount: getOrderAmountWithoutCredit(prevState),
    })),

    [ANALYTICS_SIGNUP_SUCCESS]: completeRegistration((action) => ({
        userId: action.payload.email,
    })),

    [ANALYTICS_CREATE_PURCHASE_SUBMITTED_FROM_MAGENTO]: initiatePurchase(
        (action, prevState) => ({
            id: action.payload.order_uuid,
            amount: getOrderAmountWithoutCredit(prevState),
        }),
    ),
};

export default branchEventsMap;
