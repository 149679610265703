import { ofType } from 'redux-observable';
import { switchMap, mergeMap } from 'rxjs/operators';

import { handleErrorMessageWithFallback } from '@perpay-web/observable/operators/handleErrorMessageWithFallback';
import { emitOrTimeout } from '@perpay-web/utils/observable';
import {
    createWithdrawalRequestErrors,
    createWithdrawalRequestSuccess,
    replaceWithdrawalBalance,
    withdrawalBalanceError,
} from '@perpay-web/fintech/actions/ui/withdrawal';
import {
    fetchWithdrawalBalanceV2 as fetchWithdrawalBalanceV2Action,
    fetchWithdrawalBalanceV2Success,
    fetchWithdrawalBalanceV2Error,
} from '@perpay-web/fintech/actions/entities/withdrawalBalance';
import {
    BACKEND_CREATE_WITHDRAWAL_REQUEST,
    BACKEND_FETCH_WITHDRAWAL_BALANCE,
} from '@perpay-web/fintech/constants/actionTypes';

import {
    WITHDRAWAL_BALANCE_ENDPOINT,
    WITHDRAWAL_CREATE_ENDPOINT,
} from '@perpay-web/fintech/constants/urls';

const fetchWithdrawalBalance = (action$, state$, { get }) =>
    action$.pipe(
        ofType(BACKEND_FETCH_WITHDRAWAL_BALANCE),
        switchMap(() => emitOrTimeout(get(WITHDRAWAL_BALANCE_ENDPOINT))),
        mergeMap((results) => [replaceWithdrawalBalance(results.response)]),
        handleErrorMessageWithFallback((error) => [
            withdrawalBalanceError(error),
        ]),
    );

const fetchWithdrawalBalanceV2 = (action$, state$, { get }) =>
    action$.pipe(
        ofType(fetchWithdrawalBalanceV2Action().type),
        switchMap(() => emitOrTimeout(get(WITHDRAWAL_BALANCE_ENDPOINT))),
        mergeMap((results) => [
            fetchWithdrawalBalanceV2Success(results.response),
        ]),
        handleErrorMessageWithFallback((error) => [
            fetchWithdrawalBalanceV2Error(error),
        ]),
    );

const createWithdrawalRequest = (action$, state$, { post }) =>
    action$.pipe(
        ofType(BACKEND_CREATE_WITHDRAWAL_REQUEST),
        switchMap((action) => {
            const { payload } = action;
            const accountUuid = payload;
            const requestBody = {
                to_account_uuid: accountUuid,
            };
            return emitOrTimeout(post(WITHDRAWAL_CREATE_ENDPOINT, requestBody));
        }),

        mergeMap((results) => [createWithdrawalRequestSuccess(results)]),
        handleErrorMessageWithFallback((error) => [
            createWithdrawalRequestErrors(error),
        ]),
    );

export {
    createWithdrawalRequest,
    fetchWithdrawalBalance,
    fetchWithdrawalBalanceV2,
};
