import { createDataModule } from '@perpay-web/data-module/createDataModule';
import {
    fetchCardAccountApplication,
    fetchCardAccountApplicationSuccess,
    fetchCardAccountApplicationError,
    fetchCardAccountApplicationReset,
} from '@perpay-web/fintech/actions/entities/cardAccountApplications';

const FetchCardAccountApplicationDataModule = createDataModule({
    dataRequest: fetchCardAccountApplication,
    dataSuccess: fetchCardAccountApplicationSuccess,
    dataError: fetchCardAccountApplicationError,
    dataReset: fetchCardAccountApplicationReset,
});

const getRootFetchCardAccountApplication = (state) =>
    state.dataModules.fetchCardAccountApplication;
const initialValueFetchCardAccountApplication = {};

export const fetchCardAccountApplicationDataModule =
    FetchCardAccountApplicationDataModule({
        getRoot: getRootFetchCardAccountApplication,
        initialValue: initialValueFetchCardAccountApplication,
    });

// eslint-disable-next-line import/no-unused-modules
export const getStatus = (state) =>
    fetchCardAccountApplicationDataModule.getData(state).status || '';
