import { retrieveToken } from '@perpay-web/utils/tokenUtils';
import {
    BACKEND_CREATE_PRIMARY_JOB,
    BACKEND_UPDATE_SALARY_INFO,
    BACKEND_CREATE_PHONE,
    BACKEND_SIGNUP,
    BACKEND_VERIFY_PHONE,
    BACKEND_VERIFY_CARD_INVITE_CODE,
    STORE_REPLACE_AUTH,
    STORE_CREATE_PRIMARY_JOB_ERROR,
    STORE_REPLACE_PRIMARY_JOB,
    STORE_UPDATE_SALARY_INFO_ERROR,
    STORE_REPLACE_SALARY_INFO,
    STORE_CREATE_PHONE_ERROR,
    STORE_REPLACE_PHONE,
    STORE_RESET_SIGNUP_BACKEND_ERRORS,
    STORE_SIGNUP_ERROR,
    STORE_SIGNUP_SUCCESS,
    STORE_VERIFY_PHONE_ERROR,
    STORE_VERIFY_PHONE_SUCCESS,
    STORE_PHONE_BACK_STEP,
    STORE_LANDING_PAGE_CLICK_PROFILE,
    STORE_VERIFY_INVITE_CARD_CODE_SUCCESS,
    STORE_VERIFY_INVITE_CARD_CODE_ERROR,
    STORE_CLEAR_CARD_INVITE,
} from '@perpay-web/fintech/constants/actionTypes';
import {
    ESTIMATED_NET_PAY,
    PAY_CYCLE,
    SIGNUP,
    sortSignupSteps,
} from '@perpay-web/fintech/constants/steps/signupSteps';
import {
    SET_STEP,
    VERIFY_STEP,
} from '@perpay-web/fintech/components/composite/PhoneNumberForm/Steps';
import { getOrFallback } from '@perpay-web/fintech/utils/objectUtils';
import { CARD_SIGNUP_MVP_IS_LAUNCHED } from '@perpay-web/fintech/constants/flags';

export const getSignupRequirements = () => {
    const tokens = retrieveToken();
    const signupRequirements = getOrFallback(
        ['access', 'payload', 'signup_requirements'],
        tokens,
        [SIGNUP],
    );

    return signupRequirements;
};
const getIsCardFirstUser = () => {
    const tokens = retrieveToken();
    const isCardFirstUser = getOrFallback(
        ['access', 'payload', 'is_card_first_user'],
        tokens,
        false,
    );
    return isCardFirstUser;
};
const getPrimaryJobUUID = () => {
    const tokens = retrieveToken();
    const primaryJobUUID = getOrFallback(
        ['access', 'payload', 'job_uuid'],
        tokens,
        '',
    );

    return primaryJobUUID;
};

const initialState = () => ({
    signupLoading: false,
    cardInviteLoading: false,
    signupRequirements: getSignupRequirements(),
    initialRequirements: getSignupRequirements(),
    jobUUID: getPrimaryJobUUID(),
    phoneStep: SET_STEP,
    errors: {},
    isLandingPageRedirectDashboard: false,
    isCardFirstUser: getIsCardFirstUser(),
    inviteCode: {},
    inviteCodeVerificationCompleted: false,
});

const advanceSignupFlow = (state) => ({
    signupRequirements: sortSignupSteps(state.signupRequirements).slice(1),
});

const signupReducer = (state = initialState(), action = {}) => {
    const { payload, type } = action;

    // authentication reducer will handle password_set requirement
    // for partner onboarded users. remove from signup state
    if (state.signupRequirements.includes('password_set')) {
        /* eslint no-param-reassign: "error" */
        state.signupRequirements = [
            ...state.signupRequirements.filter((x) => x !== 'password_set'),
        ];
    }
    if (state.initialRequirements.includes('password_set')) {
        /* eslint no-param-reassign: "error" */
        state.initialRequirements = [
            ...state.initialRequirements.filter((x) => x !== 'password_set'),
        ];
    }

    switch (type) {
        case BACKEND_CREATE_PRIMARY_JOB:
        case BACKEND_UPDATE_SALARY_INFO:
        case BACKEND_CREATE_PHONE:
        case BACKEND_SIGNUP:
        case BACKEND_VERIFY_PHONE:
            return { ...state, signupLoading: true, errors: {} };
        case BACKEND_VERIFY_CARD_INVITE_CODE:
            return {
                ...state,
                signupLoading: true,
                errors: {},
                cardInviteLoading: true,
            };

        case STORE_CREATE_PRIMARY_JOB_ERROR:
        case STORE_UPDATE_SALARY_INFO_ERROR:
        case STORE_CREATE_PHONE_ERROR:
        case STORE_SIGNUP_ERROR:
        case STORE_VERIFY_PHONE_ERROR:
            return {
                ...state,
                signupLoading: false,
                errors: payload,
            };
        case STORE_VERIFY_INVITE_CARD_CODE_ERROR:
            return {
                ...state,
                signupLoading: false,
                errors: payload,
                isCardFirstUser: false,
                cardInviteLoading: false,
            };
        case STORE_RESET_SIGNUP_BACKEND_ERRORS:
            return { ...state, errors: initialState.errors };

        case STORE_SIGNUP_SUCCESS:
            return {
                ...state,
                signupLoading: false,
                signupRequirements: [...payload.signupRequirements],
                initialRequirements: [
                    // ensure these are not reference-equal
                    ...payload.signupRequirements,
                ],
                inviteCodeVerificationCompleted: true,
            };

        case STORE_REPLACE_SALARY_INFO:
            return {
                ...state,
                signupLoading: false,
                signupRequirements: state.signupRequirements.filter(
                    (requirement) =>
                        ![PAY_CYCLE, ESTIMATED_NET_PAY].includes(requirement),
                ),
            };

        case STORE_REPLACE_PRIMARY_JOB:
            return {
                ...state,
                signupLoading: false,
                jobUUID: payload.uuid,
                ...advanceSignupFlow(state),
            };
        case STORE_VERIFY_INVITE_CARD_CODE_SUCCESS:
            return {
                ...state,
                signupLoading: false,
                errors: {},
                isCardFirstUser: payload.is_card_first_user,
                inviteCode: {
                    [payload.partner_name]: payload.partner_code,
                },
                cardInviteLoading: false,
            };
        // Set phone does not complete a step so it does not advance the signup flow.
        case STORE_REPLACE_PHONE:
            return {
                ...state,
                signupLoading: false,
                phoneStep: VERIFY_STEP,
                errors: {},
            };

        case STORE_PHONE_BACK_STEP:
            return {
                ...state,
                signupLoading: false,
                phoneStep: SET_STEP,
                errors: {},
            };

        case STORE_VERIFY_PHONE_SUCCESS:
            return {
                ...state,
                signupLoading: false,
                // we do not need to call advanceSignupFlow here since we refresh the token in the epic
            };

        case STORE_REPLACE_AUTH: {
            const tokens = payload;
            const signupRequirements =
                tokens.access.payload.signup_requirements;
            const jobUUID = tokens.access.payload.job_uuid;
            const isCardFirstUser = CARD_SIGNUP_MVP_IS_LAUNCHED
                ? tokens.access.payload.is_card_first_user
                : state.isCardFirstUser;
            return {
                ...state,
                signupRequirements,
                jobUUID,
                isCardFirstUser,
            };
        }

        case STORE_LANDING_PAGE_CLICK_PROFILE:
            return { ...state, isLandingPageRedirectDashboard: true };

        case STORE_CLEAR_CARD_INVITE:
            return {
                ...state,
                isCardFirstUser: false,
                inviteCode: {},
            };

        default:
            return state;
    }
};

export default signupReducer;
