import { createDataModule } from '@perpay-web/data-module/createDataModule';
import {
    fetchABTests,
    replaceABTests,
    resetABTests,
    fetchABTestsError,
} from '@perpay-web/fintech/actions/shared/abTests';

const FetchABTestsDataModule = createDataModule({
    dataRequest: fetchABTests,
    dataSuccess: replaceABTests,
    dataError: fetchABTestsError,
    dataReset: resetABTests,
});

const getRoot = (state) => state.dataModules.fetchABTests;
const initialValue = {};

export const fetchABTestsDataModule = FetchABTestsDataModule({
    getRoot,
    initialValue,
});

export const getABTests = (state) => fetchABTestsDataModule.getData(state);

const getABTestByName = (state, testName) => getABTests(state)[testName] || {};

// not currently utilized anywhere; uncomment eslint when used
// eslint-disable-next-line import/no-unused-modules
export const getABTestEnabled = (state, testName) => {
    const abTest = getABTestByName(state, testName);
    return abTest.enabled || false;
};
