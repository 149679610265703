const getABTestByName = (state, testName) =>
    state.shared.abTests.tests[testName] || [];

export const getABTestEnabled = (state, testName) => {
    const abTest = getABTestByName(state, testName);
    return abTest.enabled || false;
};

// eslint-disable-next-line
export const getUserExistedInGroup = (state, testName) => {
    const abTest = getABTestByName(state, testName);
    return abTest.userExistedInGroup || false;
};

export const getIsFetchingABTest = (state) =>
    state.shared.abTests.fetchingABTest;

export const getAttemptedEmployerVerification = (state) =>
    state.shared.abTests.attemptedEmployerVerification;
