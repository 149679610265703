import { FEATURE_ENROLLMENTS } from '@perpay-web/fintech/constants/tableNames';
import {
    ENABLED,
    OPTED_IN,
    CANCELED,
} from '@perpay-web/fintech/constants/featureEnrollmentStatuses';
import {
    LEGACY_PERPAY_PLUS,
    PERPAY_PLUS,
    CARD_AUTO_APPLY,
} from '@perpay-web/fintech/constants/featureTypes';

const perpayPlusFeatures = [PERPAY_PLUS, LEGACY_PERPAY_PLUS];

const getPerpayPlusEnrollments = (state) =>
    Object.keys(state.entities[FEATURE_ENROLLMENTS])
        .filter((uuid) =>
            perpayPlusFeatures.includes(
                state.entities[FEATURE_ENROLLMENTS][uuid].feature,
            ),
        )
        .map((uuid) => state.entities[FEATURE_ENROLLMENTS][uuid]);

const getPerpayPlusEnrollmentStatus = (state) => {
    const enrollments = getPerpayPlusEnrollments(state);
    const enrollment = enrollments[0];
    return enrollment ? enrollment.status : null;
};

export const getIsPerpayPlusOptedIn = (state) => {
    const status = getPerpayPlusEnrollmentStatus(state);
    return (status && status === OPTED_IN) || false;
};

export const getHasPerpayPlus = (state) => {
    const status = getPerpayPlusEnrollmentStatus(state);
    return (status && status !== CANCELED) || false;
};

const getCardAutoApplyEnrollments = (state) =>
    Object.keys(state.entities[FEATURE_ENROLLMENTS])
        .filter(
            (uuid) =>
                state.entities[FEATURE_ENROLLMENTS][uuid].feature ===
                CARD_AUTO_APPLY,
        )
        .map((uuid) => state.entities[FEATURE_ENROLLMENTS][uuid]);

const getCardAutoApplyEnrollmentStatus = (state) => {
    const enrollments = getCardAutoApplyEnrollments(state);
    const enrollment = enrollments[0];
    return enrollment ? enrollment.status : null;
};

export const getDoesCardAutoApplyEnrollmentExist = (state) =>
    getCardAutoApplyEnrollments(state).length > 0;

export const getIsCardAutoApplyEnabled = (state) => {
    const status = getCardAutoApplyEnrollmentStatus(state);
    return status === ENABLED;
};
