export const importResizeObserver = () =>
    import('@juggle/resize-observer').then(
        ({ ResizeObserver }) => ResizeObserver,
    );

const DEFAULT_RETRY_DELAY_MS = 1500;

const timeoutPromise = (delay) =>
    new Promise((resolve) => {
        setTimeout(resolve, delay);
    });

/**
 * Allow a promise-returning function to be reattempted after a rejection.
 * This will wait for a configurable delay before retrying the call.
 * The main use-case for this functionality is loading dynamic import chunks.
 */
export const wrapRetry = (
    importFn,
    attemptsRemaining = 1,
    delay = DEFAULT_RETRY_DELAY_MS,
) =>
    importFn().catch((error) => {
        if (attemptsRemaining === 0) {
            return Promise.reject(error);
        }

        return timeoutPromise(delay).then(() =>
            wrapRetry(importFn, attemptsRemaining - 1, delay),
        );
    });
