import {
    STORE_REPLACE_ORDER_CANCELLATION_REASONS,
    STORE_RESET_ORDER_CANCELLATION_REASONS,
} from '@perpay-web/fintech/constants/actionTypes';

const initialState = {};

const orderCancellationReasonReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case STORE_REPLACE_ORDER_CANCELLATION_REASONS:
            return { ...payload };
        case STORE_RESET_ORDER_CANCELLATION_REASONS:
            return {};
        default:
            return state;
    }
};

export default orderCancellationReasonReducer;
